<div
  [class.highlightFld]="attr | isFieldQuestioned: ITEMID"
  style="display: flex"
>
  <div *ngIf="attr | isFieldQuestioned: ITEMID" class="replyQuestionBtn">
    <button
      mat-icon-button
      color="primary"
      (click)="answerQuestionFromFld(attr)"
    >
      <mat-icon>task_alt</mat-icon>
    </button>
  </div>
  <div style="width: 100%">
    <span *ngIf="attr.fld_code === 'label'" class="title label">
      <h2 style="margin-top: 10px">
        {{ attr | translateFieldLabel }}
        <mat-divider class="itemDivider"></mat-divider>
      </h2>
    </span>

    <mat-form-field
      *ngIf="attr.fld_code === 'text'"
      class="full-width"
      style="color: black"
    >
      <mat-label class="title"
        >{{ attr | translateFieldLabel
        }}<span *ngIf="attr.isMandatory == '1'">*</span>
      </mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="attr.value"
        [value]="attr.value"
        [disabled]="DISABLED"
      />
    </mat-form-field>

    <mat-form-field *ngIf="attr.fld_code === 'email'" class="full-width">
      <mat-label class="title"
        >{{ attr | translateFieldLabel
        }}<span *ngIf="attr.isMandatory == '1'">*</span>
      </mat-label>
      <input
        matInput
        type="email"
        [(ngModel)]="attr.value"
        [value]="attr.value"
        [disabled]="DISABLED"
      />
    </mat-form-field>

    <mat-form-field *ngIf="attr.fld_code === 'number'" class="full-width">
      <mat-label class="title"
        >{{ attr | translateFieldLabel
        }}<span *ngIf="attr.isMandatory == '1'">*</span>
      </mat-label>
      <input
        matInput
        type="number"
        [(ngModel)]="attr.value"
        [value]="attr.value"
        [disabled]="DISABLED"
      />
    </mat-form-field>

    <section *ngIf="attr.fld_code === 'datetime'" class="full-width">
      <mat-label class="title"
        >{{ attr | translateFieldLabel
        }}<span *ngIf="attr.isMandatory == '1'">*</span>
      </mat-label>
      <app-datetime [ITEM]="attr" [disabled]="DISABLED"></app-datetime>
    </section>

    <div *ngIf="attr.fld_code === 'month'">
      <app-month-picker
        [ITEM]="attr"
        [disabled]="DISABLED"
        [title]="attr | translateFieldLabel"
        [isMandatory]="attr.isMandatory"
      ></app-month-picker>
    </div>

    <mat-form-field *ngIf="attr.fld_code === 'tel'" class="full-width">
      <mat-label class="title"
        >{{ attr | translateFieldLabel
        }}<span *ngIf="attr.isMandatory == '1'">*</span>
      </mat-label>
      <input
        matInput
        type="tel"
        [(ngModel)]="attr.value"
        [value]="attr.value"
        [disabled]="DISABLED"
      />
    </mat-form-field>

    <div *ngIf="attr.fld_code === 'week'">
      <app-week-picker
        [ITEM]="attr"
        [disabled]="DISABLED"
        [title]="attr | translateFieldLabel"
        [isMandatory]="attr.isMandatory"
      ></app-week-picker>
    </div>

    <section *ngIf="attr.fld_code === 'checkbox'" class="chkboxFld">
      <app-checkbox [ITEM]="attr" [disabled]="DISABLED"></app-checkbox>
      <mat-label class="title" style="margin-left: 10px"
        >{{ attr | translateFieldLabel
        }}<span *ngIf="attr.isMandatory == '1'"> * </span>
      </mat-label>
    </section>

    <section class="full-width" *ngIf="attr.fld_code === 'scale'">
      <mat-label class="title"
        >{{ attr | translateFieldLabel
        }}<span *ngIf="attr.isMandatory == '1'">*</span>
      </mat-label>
      <mat-slider
        color="primary"
        class="full-width scale"
        max="5"
        min="1"
        step="1"
        discrete
        [disabled]="DISABLED"
        showTickMarks
      >
        <input matSliderThumb [(ngModel)]="attr.value" />
      </mat-slider>
    </section>

    <section *ngIf="attr.fld_code === 'entity'">
      <mat-label class="title"
        >{{ attr | translateFieldLabel
        }}<span *ngIf="attr.isMandatory == '1'">*</span>
      </mat-label>
      <app-data-list [ENTITY]="attr.for_entity"></app-data-list>
    </section>

    <section
      *ngIf="
        attr.fld_code === 'filehistory' ||
        attr.fld_code === 'file_history_no_security'
      "
    >
      <mat-label class="title"
        >{{ attr | translateFieldLabel
        }}<span *ngIf="attr.isMandatory == '1'">*</span>
      </mat-label>
      <app-file-history
        [OWNER]="OWNER"
        [ITEM]="attr"
        [ITEMID]="ITEMID"
        [disabled]="DISABLED"
      ></app-file-history>
    </section>

    <section *ngIf="attr.fld_code === 'dropdown'">
      <app-dropdown
        [ITEM]="attr"
        [disabled]="DISABLED"
        [title]="attr | translateFieldLabel"
        [isMandatory]="attr.isMandatory"
      ></app-dropdown>
    </section>

    <section *ngIf="attr.fld_code === 'user'" style="min-height: 85px">
      <mat-label class="title"
        >{{ attr | translateFieldLabel
        }}<span *ngIf="attr.isMandatory == '1'">*</span>
      </mat-label>
      <app-user-field [ITEM]="attr" [disabled]="DISABLED"></app-user-field>
    </section>

    <section
      *ngIf="attr.fld_code === 'singleEntity'"
      style="margin-top: 15px; min-height: 40px"
    >
      <mat-label class="title"
        >{{ attr | translateFieldLabel
        }}<span *ngIf="attr.isMandatory == '1'">*</span>
      </mat-label>
      <app-single-entity-field
        [ITEM]="attr"
        [allAttr]="allAttr"
        [disabled]="DISABLED"
      >
      </app-single-entity-field>
    </section>

    <section *ngIf="attr.fld_code === 'taskgroup'">
      <mat-label class="title"
        >{{ attr | translateFieldLabel
        }}<span *ngIf="attr.isMandatory == '1'">*</span>
      </mat-label>
      <app-taskgroup-field
        [ITEM]="attr"
        [disabled]="DISABLED"
      ></app-taskgroup-field>
    </section>

    <section *ngIf="attr.fld_code === 'team'">
      <mat-label class="title"
        >{{ attr | translateFieldLabel
        }}<span *ngIf="attr.isMandatory == '1'">*</span>
      </mat-label>
      <app-team-field
        [ITEM]="attr"
        [ITEMID]="ITEMID"
        [disabled]="DISABLED"
      ></app-team-field>
    </section>

    <section *ngIf="attr.fld_code === 'hidden'">
      <input type="hidden" [(ngModel)]="attr.value" />
    </section>

    <section *ngIf="attr.fld_code === 'department'">
      <mat-label class="title"
        >{{ attr | translateFieldLabel
        }}<span *ngIf="attr.isMandatory == '1'">*</span>
      </mat-label>
      <app-department-field
        [ITEM]="attr"
        [disabled]="DISABLED"
      ></app-department-field>
    </section>

    <section *ngIf="attr.fld_code === 'productlist'">
      <mat-label class="title">{{ attr | translateFieldLabel }}</mat-label>
      <app-productlist-field
        [ITEMID]="ITEMID"
        [ITEM]="attr"
        [allAttr]="allAttr"
        [disabled]="DISABLED"
      >
      </app-productlist-field>
    </section>

    <section *ngIf="attr.fld_code === 'semiproductlist'">
      <mat-label class="title">{{ attr | translateFieldLabel }}</mat-label>
      <app-semi-productlist-field
        [ITEMID]="ITEMID"
      ></app-semi-productlist-field>
    </section>

    <section *ngIf="attr.fld_code === 'pdfExport'">
      <mat-label class="title">{{ attr | translateFieldLabel }}</mat-label>
      <app-pdf-export [ITEM]="attr" [ITEMID]="ITEMID"></app-pdf-export>
    </section>

    <section *ngIf="attr.fld_code === 'view'">
      <mat-label class="title">{{ attr | translateFieldLabel }}</mat-label>
      <app-view-field [ITEM]="attr" [ITEMID]="ITEMID"></app-view-field>
    </section>

    <section *ngIf="attr.fld_code === 'prodrecipelist'">
      <mat-label class="title">{{ attr | translateFieldLabel }}</mat-label>
      <app-prodrecipelist-field
        [ITEM]="attr"
        [ITEMID]="ITEMID"
      ></app-prodrecipelist-field>
    </section>

    <section *ngIf="attr.fld_code === 'chat'">
      <app-chat-field
        *ngIf="ITEMID != 'new'"
        [ITEM]="attr"
        [ITEMID]="ITEMID"
      ></app-chat-field>
    </section>

    <section *ngIf="attr.fld_code === 'gallery'">
      <app-gallery-field
        *ngIf="ITEMID != 'new'"
        [ITEM]="attr"
        [ITEMID]="ITEMID"
        [OWNER]="OWNER"
      ></app-gallery-field>
    </section>

    <section *ngIf="attr.fld_code === 'product_erp_stock'">
      <app-erp-product-stock-field
        [ITEM]="attr"
        [ITEMID]="ITEMID"
      ></app-erp-product-stock-field>
    </section>

    <section *ngIf="attr.fld_code === 'purchasingorder_lines'">
      <app-purchasingorderlines-field
        [ITEM]="attr"
        [ITEMID]="ITEMID"
        [allAttr]="allAttr"
      >
      </app-purchasingorderlines-field>
    </section>

    <section *ngIf="attr.fld_code == 'installationslist'">
      <app-installation-list
        [ITEM]="attr"
        [ITEMID]="ITEMID"
        [allAttr]="allAttr"
      >
      </app-installation-list>
    </section>

    <section *ngIf="attr.fld_code === 'calendar'">
      <app-calendar-view [ITEM]="attr" [ITEMID]="ITEMID"></app-calendar-view>
    </section>

    <section *ngIf="attr.fld_code === 'notes_list'">
      <mat-label class="title">{{ attr | translateFieldLabel }}</mat-label>
      <app-notes-list
        [ITEMID]="ITEMID"
        [ITEM]="attr"
        [allAttr]="allAttr"
        [disabled]="DISABLED"
      ></app-notes-list>
    </section>

    <section
      *ngIf="attr.fld_code == 'thumbnail'"
      style="display: flex; justify-content: center"
    >
      <div>
        <mat-label class="title">{{ attr | translateFieldLabel }}</mat-label>
        <app-thumbnail
          [ITEMID]="ITEMID"
          [ITEM]="attr"
          [allAttr]="allAttr"
        ></app-thumbnail>
      </div>
    </section>

    <section *ngIf="attr.fld_code === 'sprint_list'">
      <app-sprint-list [ITEMID]="ITEMID" [entCode]="entCode"> </app-sprint-list>
    </section>

    <section *ngIf="attr.fld_code === 'gantt_chart'">
      <app-gannt-chart [ITEMID]="ITEMID" [ITEM]="attr"> </app-gannt-chart>
    </section>

    <section *ngIf="attr.fld_code === 'report_view'">
      <app-report-view [ITEMID]="ITEMID"> </app-report-view>
    </section>

    <section *ngIf="attr.fld_code === 'file_preview'">
      <app-file-preview></app-file-preview>
    </section>

    <section *ngIf="attr.fld_code === 'expense_list'">
      <app-expenselist
        [ITEMID]="ITEMID"
        [ITEM]="attr"
        [allAttr]="allAttr"
        [disabled]="DISABLED"
      >
      </app-expenselist>
    </section>

    <section *ngIf="attr.fld_code === 'cost_centers'">
      <app-cost-centers [ITEM]="attr"> </app-cost-centers>
    </section>

    <section *ngIf="attr.fld_code === 'file_collection'">
      <app-file-collection [ITEM]="attr" [ITEMID]="ITEMID">
      </app-file-collection>
    </section>

    <mat-error
      class="materror"
      *ngIf="
        attr.isMandatory == '1' &&
        showMandatoryErrors &&
        (attr.value == '' || !attr.value)
      "
    >
      {{ 'Messages.mandatorFld' | translate }}
    </mat-error>
    <mat-error
      class="materror"
      *ngIf="showInvalidEndDate && attr.fld_code_ent === 'absence_end_absence'"
    >
      {{ 'Messages.hasInvalidEndDate' | translate }}
    </mat-error>
  </div>
</div>
