import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ERPMoneyaccount } from 'src/app/models/erpmoneyaccount';
import { ErpMoneyaccountService } from 'src/app/services/erp/erp-moneyaccount.service';
import { OrgChart } from 'd3-org-chart';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-erp-admin-moneyaccounts-tree',
  templateUrl: './erp-admin-moneyaccounts-tree.component.html',
  styleUrls: ['./erp-admin-moneyaccounts-tree.component.css'],
})
export class ErpAdminMoneyaccountsTreeComponent
  implements OnInit, OnChanges, AfterViewInit
{
  topMoneyAccount: any = null;
  public moneyaccountTree: ERPMoneyaccount;
  @Output() SelectMoneyAccount = new EventEmitter<any>();
  @ViewChild('chartContainer') chartContainer: ElementRef;
  chart: any;
  moneyAccountData: any[] = [];

  constructor(
    private readonly erpwMoneyaccountService: ErpMoneyaccountService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (!this.chart) {
      this.chart = new OrgChart();
    }
    this.updateChart();
  }

  ngOnChanges() {
    this.updateChart();
  }

  private getDepartmentData(account: ERPMoneyaccount, parentId: number) {
    const accountNode = {
      id: account.id,
      parentId,
      title: account.title,
      designation:
        account.type == 1
          ? this.translateService.instant('ERPMoneyaccounts.cash')
          : this.translateService.instant('ERPMoneyaccounts.check'),
    };

    this.moneyAccountData.push(accountNode);

    if (account.sub_money_accounts.length > 0) {
      for (const subAccount of account.sub_money_accounts) {
        this.getDepartmentData(subAccount, account.id);
      }
    }
  }

  getMoneyAccount() {
    this.erpwMoneyaccountService
      .getMoneyaccountsTree()
      .pipe(take(1))
      .subscribe((res) => {
        this.topMoneyAccount = null;
        this.moneyaccountTree = res;
        console.log(this.moneyaccountTree);
        this.getDepartmentData(this.moneyaccountTree, null);
        this.chart
          .container(this.chartContainer.nativeElement)
          .data(this.moneyAccountData)
          .nodeWidth((d) => 100)
          .nodeHeight((d) => 50)
          .nodeContent((node) => {
            return `<div #node style="border: darkgrey solid 1px; background-color: #efefef;
              width:${node.width}px;height:${node.height}px; text-align: center; border-radius: 5%;
              vertical-align: middle; display: table-cell">
              <strong>${node.data.title}</strong><br>${node.data.designation}
         </div>`;
          })
          .onNodeClick((d) => this.selectMoneyAccount(d.data))
          .expandAll()
          .render();
      });
  }

  updateChart() {
    console.log('asdadasd');
    if (!this.moneyAccountData) {
      return;
    }
    if (!this.chart) {
      return;
    }
    this.moneyAccountData = [];
    this.getMoneyAccount();
  }

  selectMoneyAccount(account: ERPMoneyaccount) {
    console.log(account);
    this.SelectMoneyAccount.emit(account);
  }
}
