import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { Observable, Subject } from 'rxjs';
import { SpinnerType } from '../common/enums';
import { FileCollectionFile } from '../model';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService extends BaseService {
  fileForPreviewId = new Subject<number>();
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  upload(formData) {
    return this.http
      .post<any>(`${this.apiUrl + 'file/upload'}`, formData, {
        reportProgress: true,
        observe: 'events',
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        }),
      })
      .pipe(
        map((event) => this.getEventMessage(event, formData))
        //  catchError(this.handleFileError)
      );
  }

  private handleFileError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return;
  }

  public downloadFIle(fileId: number): Observable<any> {
    return this.getFile<any>(this.apiUrl + 'file/' + fileId);
  }

  uploadThumbnail(ITEM: any, formData: FormData) {
    return this.post<any>(this.apiUrl + 'thumbnail/upload/' + ITEM, formData);
  }

  uploadFileCollection(files: File[], entdataId: number, entfieldId: number) {
    const formData = new FormData();
    const ArrayFiles = Array.from(files);
    ArrayFiles.forEach((file) => {
      formData.append('files[]', file, file.name); // Append each file to the form data
    });

    // Append entdataId and entfieldId to the form data
    formData.append('entdataId', entdataId.toString());
    formData.append('entfieldId', entfieldId.toString());

    return this.http.post<any>(
      `${this.apiUrl + 'file/multiple-upload'}`,
      formData,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          useSpinner: SpinnerType.Big + '',
        }),
      }
    );
  }

  getFileCollection(
    entdataId: number,
    entfieldId: number
  ): Observable<FileCollectionFile[]> {
    return this.get<FileCollectionFile[]>(
      `${this.apiUrl}file/file-collection/${entdataId}/${entfieldId}`
    );
  }

  downloadFileFromFileCollection(fileId: number) {
    return this.getFile<any>(`${this.apiUrl}file/file-collection/${fileId}`);
  }

  downloadMultipleFilesFromFileCollection(selectedFileIds: number[]) {
    return this.getWithPostFile<any>(
      `${this.apiUrl}file/file-collection/multiple-download`,
      { fileIds: selectedFileIds }
    );
  }

  deleteFileFromFileCollection(id: number) {
    return this.delete<any>(`${this.apiUrl}file/file-collection/delete/${id}`);
  }

  downloadToDevice(file: File | Blob, name: string) {
    // Create a Blob URL for the file
    const blobUrl = window.URL.createObjectURL(file);

    // Create a temporary anchor element
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = name;

    // Programmatically click the anchor to trigger the download
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(blobUrl);
  }
}
