import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  ElementRef,
  Input,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import { FileCollectionFile } from '../../../../../model';
import { FileUploadService } from '../../../../../services/file-upload.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NotificationService } from '../../../../../services/notification.service';
import { LayoutService } from '../../../../../services/layout.service';
import { take } from 'rxjs/operators';
import { MatLabel } from '@angular/material/form-field';
import { AsyncPipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { TranslatePipe } from '@ngx-translate/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatButton, MatIconButton } from '@angular/material/button';
import { DragAndDropDirective } from '../../../../../directives/drag-and-drop.directive';
import { FilesizePipe } from '../../../../../pipes/filesize.pipe';
import { format } from 'date-fns';

@Component({
  selector: 'app-file-collection',
  templateUrl: './file-collection.component.html',
  styleUrls: ['./file-collection.component.css'],
  standalone: true,
  imports: [
    MatLabel,
    AsyncPipe,
    MatIcon,
    TranslatePipe,
    MatCheckbox,
    MatButton,
    MatIconButton,
    DragAndDropDirective,
    FilesizePipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileCollectionComponent implements OnInit {
  @Input() ITEM: any;
  @Input() ITEMID: any;
  @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
  uploadedFiles = signal<FileCollectionFile[]>([]);

  constructor(
    private readonly fileUploadService: FileUploadService,
    private readonly destroyRef: DestroyRef,
    private readonly notificationService: NotificationService,
    public layoutService: LayoutService
  ) {}

  ngOnInit() {
    if (this.ITEMID != 'new') {
      this.getUploadedFiles();
    }
  }

  prepareFilesList(files: Array<any>) {
    this.fileUploadService
      .uploadFileCollection(files, this.ITEMID, Number(this.ITEM.id))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          this.uploadedFiles.update((files) => {
            return [...files, ...res];
          });
          this.notificationService.showSnackbarMessage(
            'Messages.uploadMultipleFilesSuccess'
          );
        },
        error: (error) => {
          this.notificationService.showSnackbarMessage(error.error.error);
        },
      });
    this.fileDropEl.nativeElement.value = '';
  }

  private getUploadedFiles() {
    this.fileUploadService
      .getFileCollection(this.ITEMID, Number(this.ITEM.id))
      .pipe(take(1))
      .subscribe((res) => this.uploadedFiles.update((_) => [...res]));
  }

  downloadFile(id: number, name: string) {
    this.fileUploadService
      .downloadFileFromFileCollection(id)
      .pipe(take(1))
      .subscribe((file) => {
        this.fileUploadService.downloadToDevice(file, name);
      });
  }

  deleteFile(id: number) {
    this.fileUploadService
      .deleteFileFromFileCollection(id)
      .pipe(take(1))
      .subscribe(() => {
        this.uploadedFiles.update((files) =>
          files.filter((file) => file.id !== id)
        );
        this.notificationService.showSnackbarMessage(
          'Messages.deleteFileFromFileCollectionSuccess'
        );
      });
  }

  updateFilesForDownload(selected: boolean, index?: number) {
    this.uploadedFiles.update((files) => {
      if (index === undefined) {
        files.forEach((file) => (file.selected = selected));
      } else {
        const i = files.findIndex((f) => f.id === index);
        files[i].selected = selected;
      }
      return [...files];
    });
  }

  partiallySelected = computed(() => {
    const files = this.uploadedFiles();
    return (
      files.some((file) => file.selected) &&
      !files.every((file) => file.selected)
    );
  });

  fullySelected = computed(() => {
    const files = this.uploadedFiles();
    return files.every((file) => file.selected);
  });

  numberOfSelected = computed(() => {
    const files = this.uploadedFiles();
    return files.filter((file) => file.selected).length;
  });

  downloadSelectedFiles() {
    const selectedFileIds = this.uploadedFiles()
      .filter((file) => file.selected)
      .map((file) => file.id);
    this.fileUploadService
      .downloadMultipleFilesFromFileCollection(selectedFileIds)
      .pipe(take(1))
      .subscribe((file) => {
        const formattedDate = format(new Date(), 'dd-MM-yyyy HH:mm');
        this.fileUploadService.downloadToDevice(
          file,
          `file-collection-files-${formattedDate}`
        );
      });
  }
}
