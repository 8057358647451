<div style="width: 100%; cursor: pointer; display: flex">
  <button
    mat-icon-button
    class="icon-button"
    (click)="dashboardService.toggleSidebar.next(true)"
    style="background-color: #dddddd; margin-top: 5px; margin-left: 5px"
  >
    <img
      class="icon"
      ngSrc="../../../assets/icons/icon-96x96.png"
      alt="teamwork-logo"
      height="96"
      width="96"
    />
  </button>
</div>

<br />

<ng-template
  #moduleCardMenu
  let-icon="icon"
  let-title="title"
  let-action="action"
>
  <div class="navMenuItem" (click)="openModule(action)">
    <mat-icon>{{ icon }}</mat-icon>
    <div style="font-size: 15px; margin-left: 15px; width: 150px">
      {{ title }}
    </div>
  </div>
</ng-template>

<mat-accordion>
  <mat-expansion-panel
    [disabled]="true"
    routerLink="/"
    (click)="dashboardService.toggleSidebar.next(true)"
    style="cursor: pointer !important"
  >
    <mat-expansion-panel-header style="color: black">
      <mat-panel-title
        ><mat-icon>home</mat-icon
        ><span style="margin-top: 3px; font-size: 15px; margin-left: 15px"
          >Αρχική</span
        ></mat-panel-title
      >
    </mat-expansion-panel-header>
  </mat-expansion-panel>
  <mat-expansion-panel
    #favoriteExpansionPanel
    *ngIf="companyService.favoriteModules.length > 0"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>{{ 'welcome.favorites' | translate }}</mat-panel-title>
    </mat-expansion-panel-header>
    <mat-list class="mat-accordion-list">
      <ng-container *ngFor="let module of companyService.favoriteModules">
        <mat-list-item
          *ngIf="module.path !== 'customentity' && module.path !== 'erp'"
          (click)="favoriteExpansionPanel.close()"
        >
          <ng-container
            [ngTemplateOutlet]="moduleCardMenu"
            [ngTemplateOutletContext]="{
              icon: module.maticon,
              title: module.title,
              action: module.path
            }"
          >
          </ng-container>
        </mat-list-item>
        <ng-container *ngIf="module.path === 'customentity'">
          <mat-list-item
            *ngFor="let entity of entityService.customEntities"
            (click)="favoriteExpansionPanel.close()"
          >
            <ng-container
              [ngTemplateOutlet]="moduleCardMenu"
              [ngTemplateOutletContext]="{
                icon: 'group_work',
                title: language === 'en' ? entity.name_en : entity.name_gr,
                action: 'customentity/entity/' + entity.entity_code
              }"
            >
            </ng-container>
          </mat-list-item>
        </ng-container>
      </ng-container>
    </mat-list>
  </mat-expansion-panel>
  <mat-expansion-panel
    #adminExpansionPanel
    *ngIf="companyService.userAdminModules.length > 0"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>Admin</mat-panel-title>
    </mat-expansion-panel-header>
    <mat-list class="mat-accordion-list">
      <mat-list-item
        *ngFor="let module of companyService.userAdminModules"
        (click)="adminExpansionPanel.close()"
      >
        <ng-container
          [ngTemplateOutlet]="moduleCardMenu"
          [ngTemplateOutletContext]="{
            icon: module.maticon,
            title: module.title,
            action: module.path
          }"
        >
        </ng-container>
      </mat-list-item>
    </mat-list>
  </mat-expansion-panel>
  <mat-expansion-panel
    #supplyExpansionPanel
    *ngIf="companyService.userSupplyChainModules.length > 0"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>{{ 'welcome.supplychain' | translate }}</mat-panel-title>
    </mat-expansion-panel-header>
    <mat-list class="mat-accordion-list">
      <mat-list-item
        *ngFor="let module of companyService.userSupplyChainModules"
        (click)="supplyExpansionPanel.close()"
      >
        <ng-container
          [ngTemplateOutlet]="moduleCardMenu"
          [ngTemplateOutletContext]="{
            icon: module.maticon,
            title: module.title,
            action: module.path
          }"
        >
        </ng-container>
      </mat-list-item>
    </mat-list>
  </mat-expansion-panel>
  <mat-expansion-panel
    #salesExpansionPanel
    *ngIf="companyService.userSalesModules.length > 0"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>{{ 'welcome.salesData' | translate }}</mat-panel-title>
    </mat-expansion-panel-header>
    <mat-list class="mat-accordion-list">
      <mat-list-item
        *ngFor="let module of companyService.userSalesModules"
        (click)="salesExpansionPanel.close()"
      >
        <ng-container
          [ngTemplateOutlet]="moduleCardMenu"
          [ngTemplateOutletContext]="{
            icon: module.maticon,
            title: module.title,
            action: module.path
          }"
        >
        </ng-container>
      </mat-list-item>
    </mat-list>
  </mat-expansion-panel>
  <mat-expansion-panel
    #managementExpansionPanel
    *ngIf="companyService.userManagementModules.length > 0"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>Management</mat-panel-title>
    </mat-expansion-panel-header>
    <mat-list class="mat-accordion-list">
      <mat-list-item
        *ngFor="let module of companyService.userManagementModules"
        (click)="managementExpansionPanel.close()"
      >
        <ng-container
          [ngTemplateOutlet]="moduleCardMenu"
          [ngTemplateOutletContext]="{
            icon: module.maticon,
            title: module.title,
            action: module.path
          }"
        >
        </ng-container>
      </mat-list-item>
    </mat-list>
  </mat-expansion-panel>
  <mat-expansion-panel
    #HRExpansionPanel
    *ngIf="companyService.userHrModules.length > 0"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>HR</mat-panel-title>
    </mat-expansion-panel-header>
    <mat-list class="mat-accordion-list">
      <mat-list-item
        *ngFor="let module of companyService.userHrModules"
        (click)="HRExpansionPanel.close()"
      >
        <ng-container
          [ngTemplateOutlet]="moduleCardMenu"
          [ngTemplateOutletContext]="{
            icon: module.maticon,
            title: module.title,
            action: module.path
          }"
        >
        </ng-container>
      </mat-list-item>
    </mat-list>
  </mat-expansion-panel>
  <mat-expansion-panel
    #ERPExpansionPanel
    *ngIf="companyService.userErpModules.length > 0"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>{{ 'welcome.erp' | translate }}</mat-panel-title>
    </mat-expansion-panel-header>
    <mat-list class="mat-accordion-list">
      <mat-list-item
        *ngFor="let erpModule of companyService.userErpModules"
        (click)="ERPExpansionPanel.close()"
      >
        <ng-container
          [ngTemplateOutlet]="moduleCardMenu"
          [ngTemplateOutletContext]="{
            icon: erpModule.maticon,
            title: translateService.instant(erpModule.title),
            action: erpModule.path
          }"
        >
        </ng-container>
      </mat-list-item>
    </mat-list>
  </mat-expansion-panel>
  <mat-expansion-panel
    #MaintenanceExpansionPanel
    *ngIf="companyService.userMaintenanceModules.length > 0"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>{{ 'Modules.maintenance' | translate }}</mat-panel-title>
    </mat-expansion-panel-header>
    <mat-list class="mat-accordion-list">
      <mat-list-item
        *ngFor="let module of companyService.userMaintenanceModules"
        (click)="MaintenanceExpansionPanel.close()"
      >
        <ng-container
          [ngTemplateOutlet]="moduleCardMenu"
          [ngTemplateOutletContext]="{
            icon: module.maticon,
            title: module.title,
            action: module.path
          }"
        >
        </ng-container>
      </mat-list-item>
    </mat-list>
  </mat-expansion-panel>

  <mat-expansion-panel
    #CustomEntityExpansionPanel
    *ngIf="companyService.userErpModules.length > 0"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>{{
        'Modules.customentity' | translate
      }}</mat-panel-title>
    </mat-expansion-panel-header>
    <mat-list class="mat-accordion-list">
      <mat-list-item
        *ngFor="let entity of entityService.customEntities"
        (click)="CustomEntityExpansionPanel.close()"
      >
        <ng-container
          [ngTemplateOutlet]="moduleCardMenu"
          [ngTemplateOutletContext]="{
            icon: 'group_work',
            title: language === 'en' ? entity.name_en : entity.name_gr,
            action: 'customentity/entity/' + entity.entity_code
          }"
        >
        </ng-container>
      </mat-list-item>
    </mat-list>
  </mat-expansion-panel>
</mat-accordion>
<br /><br /><br />

<div class="os_version">
  <span>
    <span
      ><strong>Teamwork OS {{ appVersion }}</strong></span
    >
  </span>
</div>
