import { NgModule } from '@angular/core';

import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTreeModule } from '@angular/material/tree';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatBadgeModule } from '@angular/material/badge';
import { OverlayModule } from '@angular/cdk/overlay';
import { UserListComponent } from './modules/localadmin/user-list/user-list.component';
import { GridComponent } from './common/grid/grid.component';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MyHttpInterceptor } from './interceptors/MyHttpInterceptor';
import { SideMenuComponent } from './common/side-menu/side-menu.component';
import { LoginService } from './services/login.service';
import { NotifyComponent } from './common/notify/notify.component';
import { UserFormComponent } from './user/user-form/user-form.component';
import { AvatarComponent } from './user/avatar/avatar.component';
import { ProfileComponent } from './user/profile/profile.component';
import { DepartmentListComponent } from './modules/fulladmin/new-company/department-list/department-list.component';
import { DepartmentTreeComponent } from './modules/fulladmin/new-company/department-tree/department-tree.component';
import { AvatarShowComponent } from './common/avatar/show-avatar.component';
import { ChatRoomComponent } from './modules/chat/chat-room/chat-room.component';
import { EntityComponent } from './common/entity/entity.component';
import { DataListComponent } from './common/entity/data-list/data-list.component';
import { DataItemComponent } from './common/entity/data-item/data-item.component';
import { FileHistoryComponent } from './common/entity/data-item/fields/file-history/file-history.component';
import { DataPostComponent } from './common/entity/data-post/data-post.component';
import { DropdownComponent } from './common/entity/data-item/fields/dropdown/dropdown.component';
import { UserFieldComponent } from './common/entity/data-item/fields/user-field/user-field.component';
import { EntitySelectorDiagComponent } from './common/entity-selector-diag/entity-selector-diag.component';
import { SingleEntityFieldComponent } from './common/entity/data-item/fields/single-entity-field/single-entity-field.component';
import { TaskgroupFieldComponent } from './common/entity/data-item/fields/taskgroup-field/taskgroup-field.component';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { SimpleAlertDiagComponent } from './common/simple-alert-diag/simple-alert-diag.component';
import { TeamFieldComponent } from './common/entity/data-item/fields/team-field/team-field.component';
import { DataItemDiagComponent } from './common/entity/data-item-diag/data-item-diag.component';
import { TaskListTableComponent } from './common/task-list-table/task-list-table.component';
import { DataListFiltersComponent } from './common/entity/data-list/data-list-filters/data-list-filters.component';
import { UserFilterComponent } from './common/entity/data-list/data-list-filters/user-filter/user-filter.component';
import { SingleEntityFilterComponent } from './common/entity/data-list/data-list-filters/single-entity-filter/single-entity-filter.component';
import { DataListWithFiltersComponent } from './common/entity/data-list-with-filters/data-list-with-filters.component';
import { DepartmentFieldComponent } from './common/entity/data-item/fields/department-field/department-field.component';
import { CheckboxComponent } from './common/entity/data-item/fields/checkbox/checkbox.component';
import { DatetimeComponent } from './common/entity/data-item/fields/datetime/datetime.component';
import {
  OWL_DATE_TIME_LOCALE,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { DatetimeFilterComponent } from './common/entity/data-list/data-list-filters/datetime-filter/datetime-filter.component';
import { ProductlistFieldComponent } from './common/entity/data-item/fields/productlist-field/productlist-field.component';
import { SemiProductlistFieldComponent } from './common/entity/data-item/fields/semi-productlist-field/semi-productlist-field.component';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { environment } from 'src/environments/environment';
import { FieldSelectorComponent } from './common/entity/field-selector/field-selector.component';
import { FieldsComponent } from './common/entity/data-item/fields/fields.component';
import { CheckboxFilterComponent } from './common/entity/data-list/data-list-filters/checkbox-filter/checkbox-filter.component';
import { PdfExportComponent } from './common/entity/data-item/fields/pdf-export/pdf-export.component';
import { ViewFieldComponent } from './common/entity/data-item/fields/view-field/view-field.component';
import { DataConnectedItemsComponent } from './common/entity/data-item/data-connected-items/data-connected-items.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DataReplicateComponent } from './common/entity/data-item/data-replicate/data-replicate.component';
import { EntityMenuSelectorComponent } from './common/entity-menu-selector/entity-menu-selector.component';
import { ProdrecipelistFieldComponent } from './common/entity/data-item/fields/prodrecipelist-field/prodrecipelist-field.component';
import { ChatFieldComponent } from './common/entity/data-item/fields/chat-field/chat-field.component';
import { ItemLinkDirective } from './directives/item-link.directive';
import { DataConnectedListComponent } from './common/entity/data-item/data-connected-list/data-connected-list.component';
import { GalleryFieldComponent } from './common/entity/data-item/fields/gallery-field/gallery-field.component';
import { GalleryImageComponent } from './common/entity/data-item/fields/gallery-field/gallery-image/gallery-image.component';
import { GalleryCaruselComponent } from './common/entity/data-item/fields/gallery-field/gallery-carusel/gallery-carusel.component';
import { WebcamModule } from 'ngx-webcam';
import { CameraCaptureComponent } from './common/camera-capture/camera-capture.component';
import { DataQuestionComponent } from './common/entity/data-question/data-question.component';
import { DataItemApprovalsComponent } from './common/entity/data-item/data-item-approvals/data-item-approvals.component';
import { EntitySelectorAutocompleteComponent } from './common/entity-selector-autocomplete/entity-selector-autocomplete.component';
import { ErpProductStockFieldComponent } from './common/entity/data-item/fields/erp-product-stock-field/erp-product-stock-field.component';
import { WarehouseProductHistoryComponent } from './common/erp/warehouse-product-history/warehouse-product-history.component';
import { PurchasingorderlinesFieldComponent } from './common/entity/data-item/fields/purchasingorderlines-field/purchasingorderlines-field.component';
import { CalendarViewComponent } from './common/entity/data-item/fields/calendar-view/calendar-view.component';
import { CalendarEventComponent } from './common/entity/data-item/fields/calendar-view/calendar-event/calendar-event.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { EventTeamComponent } from './common/entity/data-item/fields/calendar-view/calendar-event/event-team/event-team.component';
import { YesNoAlertDiagComponent } from './common/yes-no-alert-diag/yes-no-alert-diag.component';
import { ErpAdminWarehouseTreeComponent } from './modules/localadmin/erp-admin/erp-admin-warehouse-list/erp-admin-warehouse-tree/erp-admin-warehouse-tree.component';
import { NumberFormatPipe } from './directives/number.pipi';
import { ErpAdminMoneyaccountsTreeComponent } from './modules/localadmin/erp-admin/erp-admin-moneyaccounts-list/erp-admin-moneyaccounts-tree/erp-admin-moneyaccounts-tree.component';
import { StepValuePipe } from './pipes/step-value.pipe';
import { NotesListComponent } from './common/entity/data-item/fields/notes-list/notes-list.component';
import { TransformEntityToErpComponent } from './common/erp/transformation/transform-entity-to-erp/transform-entity-to-erp.component';
import { InstallationListComponent } from './common/entity/data-item/fields/installation-list/installation-list.component';
import { ThumbnailComponent } from './common/entity/data-item/fields/thumbnail/thumbnail.component';
import { EntitySelectorDiagMultipleComponent } from './common/entity-selector-diag-multiple/entity-selector-diag-multiple.component';
import { DateCreationPeriodPipe } from './pipes/date-creation-period.pipe';
import { StatisticsFunctionPipe } from './pipes/statistics-function.pipe';
import { MonthPickerComponent } from './common/entity/data-item/fields/month-picker/month-picker.component';
import { WeekPickerComponent } from './common/entity/data-item/fields/week-picker/week-picker.component';
import { ProdScheduleSprintPipe } from './pipes/prod-schedule-sprint.pipe';
import { SprintListComponent } from './common/entity/data-item/fields/sprint-list/sprint-list.component';
import { ProdScheduleSprintComponent } from './common/entity/data-item/fields/sprint-list/prod-schedule-sprint/prod-schedule-sprint.component';
import { ProjectSprintComponent } from './common/entity/data-item/fields/sprint-list/project-sprint/project-sprint.component';
import { SpringDiagComponent } from './common/entity/data-item/fields/sprint-list/spring-diag/spring-diag.component';
import { GanntChartComponent } from './common/entity/data-item/fields/gannt-chart/gannt-chart.component';
import { ReportViewComponent } from './common/entity/data-item/fields/report-view/report-view.component';
import { FilePreviewComponent } from './common/entity/data-item/fields/file-preview/file-preview.component';
import { DashboardComponent } from './modules/welcome/dashboard/dashboard.component';
import { RouterLink, RouterOutlet } from '@angular/router';
import { ExpenseListComponent } from './common/entity/data-item/fields/expenselist/expenselist.component';
import { DateComponent } from './common/entity/data-item/fields/date/date.component';
import { CostCentersComponent } from './common/entity/data-item/fields/cost-centers/cost-centers.component';
import { FavoritesSelectorComponent } from './common/favorites-selector/favorites-selector.component';
import { LOAD_WASM, NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { QrcodeScannerComponent } from './common/qrcode-scanner/qrcode-scanner.component';
import { SidebarComponent } from './common/sidebar/sidebar.component';
import { SidebarContentComponent } from './common/sidebar/sidebar-content/sidebar-content.component';
import { TranslateFieldLabelPipe } from './pipes/translate-field-label.pipe';
import { IsFieldQuestionedPipe } from './pipes/is-field-questioned.pipe';
import { FieldColumnSpanPipe } from './pipes/field-column-span.pipe';
import { FieldRowSpanPipe } from './pipes/field-row-span.pipe';
import { WorkflowStepQuestionEntfieldPipe } from './pipes/workflow-step-question-entfield.pipe';
import { WorkflowCompletionStatusPipe } from './pipes/workflow-completion-status.pipe';
import { FileErrorInterceptor } from './interceptors/file-error.interceptor';
import { ChildProductsDialogComponent } from './common/child-products-dialog/child-products-dialog.component';
import { MonthFilterComponent } from './common/entity/data-list/data-list-filters/month-filter/month-filter.component';
import { DropdownFilterComponent } from './common/entity/data-list/data-list-filters/dropdown-filter/dropdown-filter.component';
import { FileCollectionComponent } from './common/entity/data-item/fields/file-collection/file-collection.component';
import { HideFileHistoryButtonPipe } from './pipes/hide-file-history-button.pipe';

// Necessary to solve the problem of losing internet connection
LOAD_WASM().subscribe();

@NgModule({
  declarations: [
    UserListComponent,
    GridComponent,
    SideMenuComponent,
    NotifyComponent,
    UserFormComponent,
    AvatarComponent,
    ProfileComponent,
    DepartmentListComponent,
    DepartmentTreeComponent,
    AvatarShowComponent,
    ChatRoomComponent,
    EntityComponent,
    DataListComponent,
    DataItemComponent,
    FileHistoryComponent,
    DataPostComponent,
    DropdownComponent,
    UserFieldComponent,
    EntitySelectorDiagComponent,
    SingleEntityFieldComponent,
    TaskgroupFieldComponent,
    SimpleAlertDiagComponent,
    TeamFieldComponent,
    DataItemDiagComponent,
    TaskListTableComponent,
    DataListFiltersComponent,
    UserFilterComponent,
    SingleEntityFilterComponent,
    DataListWithFiltersComponent,
    DepartmentFieldComponent,
    CheckboxComponent,
    DatetimeComponent,
    DatetimeFilterComponent,
    ProductlistFieldComponent,
    SemiProductlistFieldComponent,
    FieldSelectorComponent,
    FieldsComponent,
    CheckboxFilterComponent,
    PdfExportComponent,
    ViewFieldComponent,
    DataConnectedItemsComponent,
    DataReplicateComponent,
    EntityMenuSelectorComponent,
    ProdrecipelistFieldComponent,
    ChatFieldComponent,
    ItemLinkDirective,
    DataConnectedListComponent,
    GalleryFieldComponent,
    GalleryImageComponent,
    GalleryCaruselComponent,
    CameraCaptureComponent,
    DataQuestionComponent,
    DataItemApprovalsComponent,
    EntitySelectorAutocompleteComponent,
    ErpProductStockFieldComponent,
    WarehouseProductHistoryComponent,
    PurchasingorderlinesFieldComponent,
    CalendarViewComponent,
    CalendarEventComponent,
    EventTeamComponent,
    YesNoAlertDiagComponent,
    ErpAdminWarehouseTreeComponent,
    ErpAdminMoneyaccountsTreeComponent,
    NumberFormatPipe,
    StepValuePipe,
    NotesListComponent,
    TransformEntityToErpComponent,
    InstallationListComponent,
    ThumbnailComponent,
    EntitySelectorDiagMultipleComponent,
    DateCreationPeriodPipe,
    StatisticsFunctionPipe,
    WeekPickerComponent,
    ProdScheduleSprintPipe,
    SprintListComponent,
    ProdScheduleSprintComponent,
    ProjectSprintComponent,
    SpringDiagComponent,
    GanntChartComponent,
    ReportViewComponent,
    FilePreviewComponent,
    DashboardComponent,
    ExpenseListComponent,
    DateComponent,
    CostCentersComponent,
    FavoritesSelectorComponent,
    QrcodeScannerComponent,
    SidebarComponent,
    SidebarContentComponent,
    FieldColumnSpanPipe,
    FieldRowSpanPipe,
    WorkflowStepQuestionEntfieldPipe,
    WorkflowCompletionStatusPipe,
    ChildProductsDialogComponent,
  ],
  exports: [
    DataConnectedListComponent,
    WebcamModule,
    EntityMenuSelectorComponent,
    ClipboardModule,
    DragDropModule,
    CheckboxComponent,
    DatetimeComponent,
    DropdownComponent,
    FieldsComponent,
    DepartmentFieldComponent,
    TeamFieldComponent,
    SingleEntityFieldComponent,
    TaskgroupFieldComponent,
    UserFieldComponent,
    ProductlistFieldComponent,
    SemiProductlistFieldComponent,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ChatRoomComponent,
    Ng2GoogleChartsModule,
    UserFormComponent,
    AvatarComponent,
    DepartmentListComponent,
    DepartmentTreeComponent,
    DataListComponent,
    DataItemComponent,
    FileHistoryComponent,
    DataListWithFiltersComponent,
    TaskListTableComponent,
    DataPostComponent,
    TranslateModule,
    AvatarShowComponent,
    GridComponent,
    SideMenuComponent,
    NotifyComponent,
    UserListComponent,
    ReactiveFormsModule,
    FormsModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatTreeModule,
    MatCheckboxModule,
    OverlayModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    DataConnectedItemsComponent,
    EntitySelectorAutocompleteComponent,
    ErpAdminWarehouseTreeComponent,
    ErpAdminMoneyaccountsTreeComponent,
    NumberFormatPipe,
    DateCreationPeriodPipe,
    StatisticsFunctionPipe,
    DashboardComponent,
    FavoritesSelectorComponent,
    SidebarComponent,
    TranslateFieldLabelPipe,
    WorkflowStepQuestionEntfieldPipe,
    WorkflowCompletionStatusPipe,
  ],
  bootstrap: [AppComponent],
  imports: [
    WebcamModule,
    DragDropModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    Ng2GoogleChartsModule,
    MatBadgeModule,
    CommonModule,
    MatBottomSheetModule,
    MatTreeModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    OverlayModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    FormsModule,
    MonthPickerComponent,
    TranslateModule.forChild({
      extend: true,
      isolate: false,
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    RouterLink,
    NgxScannerQrcodeModule,
    RouterOutlet,
    NgOptimizedImage,
    MonthFilterComponent,
    DropdownFilterComponent,
    TranslateFieldLabelPipe,
    FileCollectionComponent,
    HideFileHistoryButtonPipe,
    IsFieldQuestionedPipe,
  ],
  providers: [
    NumberFormatPipe,
    { provide: MAT_DATE_LOCALE, useValue: 'gr' },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'gr' },
    { provide: HTTP_INTERCEPTORS, useClass: MyHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: FileErrorInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class GenericModule {
  constructor(
    private readonly translateService: TranslateService,
    private readonly loginService: LoginService
  ) {
    const currentUser = this.loginService.getLoginUser();

    if (currentUser) {
      this.translateService.setDefaultLang(currentUser.lang);
    } else {
      this.translateService.setDefaultLang('gr');
    }
  }
}
