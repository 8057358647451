<div class="avatar-container">
  @if (avatarBase64 == '') {
    <mat-icon class="icon"> account_circle </mat-icon>
  } @else {
    <img [src]="avatarBase64" class="avatar" />
  }
  @if (SHOWCONNECTIVITY) {
    <div
      class="connectBullet"
      [ngClass]="{
        connected: isUserConnected(),
        disconneted: !isUserConnected()
      }"
      (click)="openChatDiag()"
      aria-label="user connection status"
    ></div>
  }
</div>
