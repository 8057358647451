import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { EntityService } from '../../services/entity.service';
import { ConfQuoteLine, ConfQuote, Field, Entdata } from '../../model';
import { EntityItemFilter } from '../filters';
import { map, switchMap, take } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { SettingsService } from 'src/app/services/settings.service';
import { ErpWarehouseService } from 'src/app/services/erp/erp-warehouse.service';
import { SalesDataService } from 'src/app/services/sales-data.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-child-products-dialog',
  templateUrl: './child-products-dialog.component.html',
  styleUrls: ['./child-products-dialog.component.css'],
})
export class ChildProductsDialogComponent implements OnInit {
  dialogData: {
    colors: string[];
    sizes: string[];
    tableData: {
      [color: string]: {
        [size: string]: {
          qty: number;
          stock: number;
          currentYearSales?: number;
          lastYearSales?: number;
          customerStock?: number;
        };
      };
    };
    simpleProducts: {
      [productName: string]: {
        qty: number;
        stock: number;
        currentYearSales?: number;
        lastYearSales?: number;
        customerStock?: number;
      };
    };
    children: any[];
    validCombinations: Set<string>;
  } = {
    colors: [],
    sizes: [],
    tableData: {},
    children: [],
    validCombinations: new Set<string>(),
    simpleProducts: {},
  };
  tableData: { [color: string]: { [size: string]: number } } = {};
  displayedPopColumns: string[] = [];
  confQuote: ConfQuote;
  addressItem: any;
  addressId: string;
  allAttr: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChildProductsDialogComponent>,
    private entityService: EntityService,
    private settingsService: SettingsService,
    private erpWarehouseService: ErpWarehouseService,
    private salesDataService: SalesDataService
  ) {
    this.confQuote = data?.confQuote;
    this.allAttr = data?.allAttr || [];
  }

  ngOnInit(): void {
    if (this.data?.parentId) {
      this.loadChildren(this.data.parentId);
    }

    let addressAttr = this.allAttr.find(
      (x) => x.fld_code == 'singleEntity' && x.for_entity == 'address'
    );
    this.addressId = addressAttr?.value;
    if (addressAttr && addressAttr.value) {
      this.getAddressItem(addressAttr.value);
    }
  }

  ngDoCheck(): void {
    let tmpaddressAttr = this.allAttr.find(
      (x) => x.fld_code == 'singleEntity' && x.for_entity == 'address'
    )?.value;

    if (this.addressId != tmpaddressAttr && tmpaddressAttr) {
      this.addressId = tmpaddressAttr;
      this.getAddressItem(this.addressId);
    }
  }

  private loadChildren(parentId: number): void {
    this.getChildrenProducts(parentId)
      .pipe(take(1))
      .subscribe((children) => {
        if (children.length > 0) {
          this.prepareDialogData(children);
        }
      });
  }

  private prepareDialogData(children: any[]): void {
    const colors = new Set<string>();
    const sizes = new Set<string>();
    const validCombinations = new Set<string>();

    const tableData: {
      [color: string]: {
        [size: string]: {
          qty: number;
          stock: number;
          currentYearSales?: number;
          lastYearSales?: number;
          customerStock?: number;
        };
      };
    } = {};

    const simpleProducts: {
      [productName: string]: {
        qty: number;
        stock: number;
        currentYearSales?: number;
        lastYearSales?: number;
        customerStock?: number;
      };
    } = {};

    const customerField = this.allAttr.find(
      (attr) =>
        attr.fld_code_ent === 'quote_account' ||
        attr.fld_code_ent === 'order_account'
    );
    const customerId = customerField?.singleItem?.id;

    this.settingsService
      .getMrpErpSettings('mrp_erp_stock')
      .pipe(
        map((settings) => settings.some((setting) => setting.value === 'true')),
        switchMap((isMrpErpStockEnabled) => {
          const stockFetchObservables = children.map((child) => {
            const productCodeField = child.fields.find(
              (field: any) => field.fieldCode === 'product_code'
            );
            const productNameField = child.fields.find(
              (field: any) => field.fieldCode === 'product_name'
            );
            const productStockField = child.fields.find(
              (field: any) => field.fieldCode === 'product_stock'
            );

            if (!productCodeField || !productCodeField.value) return of(null);

            const productCode = productCodeField.value;
            const productName = productNameField?.value || 'Unnamed Product';
            const stock = productStockField?.value || 0;
            const parts = productCode.split('/');

            if (parts.length === 1) {
              // Simple Product Logic
              simpleProducts[productName] = { qty: 0, stock };

              if (isMrpErpStockEnabled) {
                // Fetch warehouse stock for simple products
                return this.erpWarehouseService
                  .getProductWhStocks(child.id)
                  .pipe(
                    map((stocks) => {
                      const totalStock = stocks.reduce(
                        (sum, warehouseStock) =>
                          sum + (warehouseStock.stock || 0),
                        0
                      );

                      // Debug: Verify the total stock for simple products
                      console.log(
                        `Simple Product ${productName} - Total Stock:`,
                        totalStock
                      );

                      simpleProducts[productName].stock = totalStock; // Assign total stock
                    }),
                    switchMap(() =>
                      customerId
                        ? this.salesDataService
                            .getSalesData(customerId, child.id)
                            .pipe(
                              map((salesResponse) => {
                                const salesData = salesResponse?.data || null;
                                if (salesData) {
                                  simpleProducts[productName].currentYearSales =
                                    salesData.currentYearSales;
                                  simpleProducts[productName].lastYearSales =
                                    salesData.lastYearSales;
                                  simpleProducts[productName].customerStock =
                                    salesData.customerStock;
                                }
                              })
                            )
                        : of(null)
                    )
                  );
              } else if (customerId) {
                // Fetch only sales data if warehouse stock is not enabled
                return this.salesDataService
                  .getSalesData(customerId, child.id)
                  .pipe(
                    map((salesResponse) => {
                      const salesData = salesResponse?.data || null;
                      if (salesData) {
                        simpleProducts[productName].currentYearSales =
                          salesData.currentYearSales;
                        simpleProducts[productName].lastYearSales =
                          salesData.lastYearSales;
                        simpleProducts[productName].customerStock =
                          salesData.customerStock;
                      }
                    })
                  );
              }

              return of(null); // No further action needed for simple products without customerId
            }

            // Color/Size Product Logic
            const color = parts[parts.length - 2];
            const size = parts[parts.length - 1];
            colors.add(color);
            sizes.add(size);
            validCombinations.add(`${color}/${size}`);

            if (!tableData[color]) tableData[color] = {};
            tableData[color][size] = { qty: 0, stock };

            if (isMrpErpStockEnabled) {
              // Fetch warehouse stock for color/size products
              return this.erpWarehouseService.getProductWhStocks(child.id).pipe(
                map((stocks) => {
                  const totalStock = stocks.reduce(
                    (sum, warehouseStock) => sum + (warehouseStock.stock || 0),
                    0
                  );

                  // Debug: Ensure totalStock calculation is correct
                  console.log(
                    `Color/Size Product ${color}/${size} - Total Stock:`,
                    totalStock
                  );

                  tableData[color][size].stock = totalStock;
                }),
                switchMap(() =>
                  customerId
                    ? this.salesDataService
                        .getSalesData(customerId, child.id)
                        .pipe(
                          map((salesResponse) => {
                            const salesData = salesResponse?.data || null;
                            if (salesData) {
                              tableData[color][size].currentYearSales =
                                salesData.currentYearSales;
                              tableData[color][size].lastYearSales =
                                salesData.lastYearSales;
                              tableData[color][size].customerStock =
                                salesData.customerStock;
                            }
                          })
                        )
                    : of(null)
                )
              );
            } else if (customerId) {
              // Fetch only sales data for color/size products if warehouse stock is not enabled
              return this.salesDataService
                .getSalesData(customerId, child.id)
                .pipe(
                  map((salesResponse) => {
                    const salesData = salesResponse?.data || null;
                    if (salesData) {
                      tableData[color][size].currentYearSales =
                        salesData.currentYearSales;
                      tableData[color][size].lastYearSales =
                        salesData.lastYearSales;
                      tableData[color][size].customerStock =
                        salesData.customerStock;
                    }
                  })
                );
            }

            return of(null); // No further action for color/size products without customerId
          });

          return forkJoin(stockFetchObservables.filter((obs) => obs !== null));
        })
      )
      .subscribe({
        next: () => {
          this.dialogData = {
            colors: Array.from(colors),
            sizes: Array.from(sizes).sort((a, b) => +a - +b),
            tableData,
            simpleProducts,
            validCombinations,
            children,
          };

          this.displayedPopColumns = this.dialogData.colors.length
            ? ['color', ...this.dialogData.sizes]
            : ['product', 'qty'];
        },
        error: (err) => {
          console.error('Error in prepareDialogData:', err);
        },
      });
  }

  private processSelectedItems(dialogData: any): void {
    const linesToAdd: ConfQuoteLine[] = [];
    const currentMaxAA =
      this.confQuote.quotelines.length > 0
        ? Math.max(...this.confQuote.quotelines.map((line) => line.aa - 1 || 0))
        : 0;

    let nextAA = currentMaxAA + 1;

    const observables: Observable<Entdata>[] = [];
    const childProductDetails: [number, number][] = [];

    Object.keys(dialogData.tableData).forEach((color) => {
      Object.keys(dialogData.tableData[color]).forEach((size) => {
        const qty = dialogData.tableData[color][size]?.qty || 0;

        if (qty > 0) {
          const productCode = `${color}/${size}`;
          const productEntry = dialogData.children.find((child) =>
            child.fields.some(
              (field: any) =>
                field.fieldCode === 'product_code' &&
                field.value.endsWith(`/${productCode}`)
            )
          );

          if (productEntry) {
            observables.push(
              this.entityService.geEntityItem('product', productEntry.id)
            );
            childProductDetails.push([qty, nextAA++]);
          }
        }
      });
    });

    // Process simple products
    Object.keys(dialogData.simpleProducts).forEach((productName) => {
      const product = dialogData.simpleProducts[productName];
      if (product.qty > 0) {
        const productEntry = dialogData.children.find((child) =>
          child.fields.some(
            (field: any) =>
              field.fieldCode === 'product_name' && field.value === productName
          )
        );

        if (productEntry) {
          observables.push(
            this.entityService.geEntityItem('product', productEntry.id)
          );
          childProductDetails.push([product.qty, nextAA++]);
        }
      }
    });

    // Execute all Observables
    forkJoin(observables)
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          for (let i = 0; i < result.length; i++) {
            linesToAdd.push(
              this.createNewLine(
                result[i],
                childProductDetails[i][0],
                childProductDetails[i][1]
              )
            );
          }
          this.dialogRef.close(linesToAdd);
        }
      });
  }

  private createNewLine(product: any, qty: number, aa: number): ConfQuoteLine {
    if (!product || !product.fields) {
      throw new Error('Product object is invalid or missing fields.');
    }

    const productPriceField = product.fields.find(
      (field: any) => field.field.fld_code === 'product_price_per_piece'
    );

    const productNameField = product.fields.find(
      (field: any) => field.field.fld_code === 'product_name'
    );
    const productCodeField = product.fields.find(
      (field: any) => field.field.fld_code === 'product_code'
    );

    const productPricePerPiece = productPriceField?.value || 0;

    const newLine = new ConfQuoteLine();
    newLine.product = {
      id: product.id,
      product_name: productNameField?.value,
      product_code: productCodeField?.value,
    };
    newLine.product_id = product.id;
    newLine.qty = qty;
    newLine.price = +productPricePerPiece;
    newLine.availability = 1;
    newLine.numFld1 = 0;
    newLine.discount = this.data?.totalDiscount ?? 0;
    newLine.discount2 = 0;
    newLine.unit = 0;
    newLine.aa = aa;

    let kathestosPerioxis = this.addressItem?.data?.find(
      (x) => x.fld_code_ent == 'address_fpa'
    )?.value;

    const vatLookupTable = {
      'Κανονικός 24% + Κανονικό': 24,
      'Κανονικός 24% + Μειωμένο (-30%)': 17,
      'Κανονικός 24% + Χωρίς ΦΠΑ': 0,
      'Μειωμένος 13% + Κανονικό': 13,
      'Μειωμένος 13% + Μειωμένο (-30%)': 9,
      'Μειωμένος 13% + Χωρίς ΦΠΑ': 0,
      'Υπερμειωμένος 6% + Κανονικό': 6,
      'Υπερμειωμένος 6% + Μειωμένο (-30%)': 4,
      'Υπερμειωμένος 6% + Χωρίς ΦΠΑ': 0,
    };

    const combinedKey = product.product_vat + ' + ' + kathestosPerioxis;
    newLine.vat = vatLookupTable[combinedKey] ?? 24;

    return newLine;
  }

  private getChildrenProducts(parentId: number): Observable<any[]> {
    return this.entityService.getFldsEntityByNameByCompany('product', 1).pipe(
      take(1),
      switchMap((response) => {
        const fields = response.fields;
        const productChildOfField = fields.find(
          (field: any) => field.fld_code === 'product_child_of'
        );
        if (!productChildOfField) {
          throw new Error(
            'Field "product_child_of" not found in entity "product".'
          );
        }

        const filter: EntityItemFilter = new EntityItemFilter();
        filter.pageSize = 500;
        filter.searchFields = [
          {
            id: productChildOfField.id,
            fld_code: 'product_child_of',
            search_from: String(parentId),
          } as Field,
        ];
        return this.entityService.getEntityDataList(filter, 'product');
      }),
      map((response) => {
        return response.data.filter((child) => child.id !== parentId);
      })
    );
  }

  confirmSelection(dialogData: any): void {
    this.processSelectedItems(dialogData);
  }

  static openChildProductsDialog(
    dialog: MatDialog,
    parentId: number,
    currentMaxAA: number,
    totalDiscount: number,
    confQuote: ConfQuote,
    callback: (linesToAdd: ConfQuoteLine[]) => void,
    allAttr: any
  ): void {
    const width = window.innerWidth > 599 ? '60%' : '99%';
    const dialogRef = dialog.open(ChildProductsDialogComponent, {
      width: width,
      maxWidth: '100vw',
      data: {
        parentId,
        currentMaxAA,
        totalDiscount,
        confQuote,
        allAttr, // Pass `allAttr` here
      },
    });

    dialogRef.afterClosed().subscribe((linesToAdd: ConfQuoteLine[]) => {
      if (linesToAdd) {
        callback(linesToAdd);
      }
    });
  }

  private getAddressItem(addressId: string) {
    this.entityService
      .geEntityItem('address', addressId)
      .pipe(take(1))
      .subscribe((resItem) => {
        this.addressItem = resItem;
      });
  }
}
