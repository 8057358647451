<h1 mat-dialog-title>{{ 'Generic.availableDimensions' | translate }}</h1>
<div mat-dialog-content>
  <div class="scrollable-container">
    <mat-table
      *ngIf="dialogData.colors.length"
      [dataSource]="dialogData.colors"
      class="mat-elevation-z8 full-width"
    >
      <!-- Static Color Column -->
      <ng-container matColumnDef="color">
        <mat-header-cell *matHeaderCellDef style="width: 20%">
          Color
        </mat-header-cell>
        <mat-cell *matCellDef="let color"> {{ color }} </mat-cell>
      </ng-container>

      <!-- Dynamic Size Columns -->
      <ng-container *ngFor="let size of dialogData.sizes" [matColumnDef]="size">
        <mat-header-cell *matHeaderCellDef> {{ size }} </mat-header-cell>
        <mat-cell *matCellDef="let color">
          <div *ngIf="dialogData.tableData[color]?.[size]">
            <mat-form-field class="full-width">
              <input
                matInput
                type="number"
                [(ngModel)]="dialogData.tableData[color][size].qty"
                placeholder="Qty"
                [min]="0"
                [disabled]="
                  !dialogData.validCombinations.has(color + '/' + size)
                "
              />
            </mat-form-field>
            <div>
              <span class="stock-value"
                >Stock:
                {{ dialogData.tableData[color][size]?.stock || 0 }}</span
              ><br />
              <span
                class="stock-value"
                *ngIf="dialogData.tableData[color][size]?.currentYearSales"
              >
                Sales This Year:
                {{ dialogData.tableData[color][size]?.currentYearSales }} </span
              ><br />
              <span
                class="stock-value"
                *ngIf="dialogData.tableData[color][size]?.lastYearSales"
              >
                Sales Last Year:
                {{ dialogData.tableData[color][size]?.lastYearSales }} </span
              ><br />
              <span
                class="stock-value"
                *ngIf="dialogData.tableData[color][size]?.customerStock"
              >
                Τελευταία Απογραφή:
                {{ dialogData.tableData[color][size]?.customerStock }}
              </span>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <!-- Header and Row Definitions -->
      <mat-header-row *matHeaderRowDef="displayedPopColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedPopColumns"></mat-row>
    </mat-table>

    <!-- Table for Simple Products -->
    <mat-table
      *ngIf="!dialogData.colors.length"
      [dataSource]="dialogData.simpleProducts | keyvalue"
      class="mat-elevation-z8 full-width"
    >
      <!-- Product Name Column -->
      <ng-container matColumnDef="product">
        <mat-header-cell *matHeaderCellDef>
          {{ 'QuoteConfigurator.products' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.key }} </mat-cell>
      </ng-container>

      <!-- Quantity Column -->
      <ng-container matColumnDef="qty">
        <mat-header-cell *matHeaderCellDef>
          {{ 'QuoteConfigurator.qty' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-form-field class="full-width">
            <input
              matInput
              type="number"
              [(ngModel)]="row.value.qty"
              [min]="0"
              placeholder="Qty"
            />
          </mat-form-field>
          <div>
            <span class="stock-value-simple"
              >Stock: {{ row.value.stock || 0 }}</span
            ><br />
            <span class="stock-value-simple" *ngIf="row.value.currentYearSales"
              >Sales This Year: {{ row.value.currentYearSales }}</span
            ><br />
            <span class="stock-value-simple" *ngIf="row.value.lastYearSales"
              >Sales Last Year: {{ row.value.lastYearSales }}</span
            ><br />
            <span class="stock-value-simple" *ngIf="row.value.lastYearSales"
              >Τελευταία Απογραφή: {{ row.value.customerStock }}</span
            >
          </div>
        </mat-cell>
      </ng-container>

      <!-- Header and Row Definitions -->
      <mat-header-row *matHeaderRowDef="['product', 'qty']"></mat-header-row>
      <mat-row *matRowDef="let row; columns: ['product', 'qty']"></mat-row>
    </mat-table>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>{{ 'Generic.close' | translate }}</button>
  <button mat-button color="primary" (click)="confirmSelection(dialogData)">
    {{ 'Generic.add' | translate }}
  </button>
</div>
