<div style="width: 95vw; margin-left: auto; margin-right: auto">
  <br /><br />

  <app-dashboard></app-dashboard>

  <div>
    <ng-template
      #moduleCard
      let-icon="icon"
      let-title="title"
      let-action="action"
      let-scope="scope"
    >
      <a class="module" [routerLink]="action">
        <div>
          <mat-icon
            style="margin-top: 10px; height: 50px; width: 50px; font-size: 50px"
            >{{ icon }}
          </mat-icon>
        </div>
        <div>
          {{ title }}
        </div>
      </a>
    </ng-template>

    <mat-tab-group
      *ngIf="showTabs"
      [selectedIndex]="selectedTabIndex"
      (selectedIndexChange)="setTabState($event)"
      style="min-height: 800px"
    >
      <mat-tab
        label="{{ 'welcome.favorites' | translate }}"
        *ngIf="companyService.favoriteModules.length > 0"
      >
        <mat-grid-list
          [cols]="tabCols"
          rowHeight="130px"
          style="margin-top: 10px"
        >
          <ng-container *ngFor="let module of companyService.favoriteModules">
            <mat-grid-tile
              *ngIf="module.path !== 'customentity' && module.path !== 'erp'"
            >
              <ng-container
                [ngTemplateOutlet]="moduleCard"
                [ngTemplateOutletContext]="{
                  icon: module.maticon,
                  title: module.title,
                  action: module.path,
                  scope: module.scope
                }"
              >
              </ng-container>
            </mat-grid-tile>
            <ng-container *ngIf="module.path === 'customentity'">
              <mat-grid-tile
                *ngFor="
                  let entity of entityService.storedActiveCustomEntities$
                    | async
                "
              >
                <ng-container
                  [ngTemplateOutlet]="moduleCard"
                  [ngTemplateOutletContext]="{
                    icon: 'group_work',
                    title: language === 'en' ? entity.name_en : entity.name_gr,
                    action: 'customentity/entity/' + entity.entity_code,
                    scope: 'customentity'
                  }"
                >
                </ng-container>
              </mat-grid-tile>
            </ng-container>
          </ng-container>
        </mat-grid-list>
      </mat-tab>
      <mat-tab label="Admin" *ngIf="companyService.userAdminModules.length > 0">
        <mat-grid-list
          [cols]="tabCols"
          rowHeight="130px"
          style="margin-top: 10px"
        >
          <mat-grid-tile *ngFor="let module of companyService.userAdminModules">
            <ng-container
              [ngTemplateOutlet]="moduleCard"
              [ngTemplateOutletContext]="{
                icon: module.maticon,
                title: module.title,
                action: module.path,
                scope: module.scope
              }"
            >
            </ng-container>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-tab>
      <mat-tab
        label="{{ 'welcome.supplychain' | translate }}"
        *ngIf="companyService.userSupplyChainModules.length > 0"
      >
        <mat-grid-list
          [cols]="tabCols"
          rowHeight="130px"
          style="margin-top: 10px"
        >
          <mat-grid-tile
            *ngFor="let module of companyService.userSupplyChainModules"
          >
            <ng-container
              [ngTemplateOutlet]="moduleCard"
              [ngTemplateOutletContext]="{
                icon: module.maticon,
                title: module.title,
                action: module.path,
                scope: module.scope
              }"
            >
            </ng-container>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-tab>
      <mat-tab
        label="{{ 'welcome.salesData' | translate }}"
        *ngIf="companyService.userSalesModules.length > 0"
      >
        <mat-grid-list
          [cols]="tabCols"
          rowHeight="130px"
          style="margin-top: 10px"
        >
          <mat-grid-tile *ngFor="let module of companyService.userSalesModules">
            <ng-container
              [ngTemplateOutlet]="moduleCard"
              [ngTemplateOutletContext]="{
                icon: module.maticon,
                title: module.title,
                action: module.path,
                scope: module.scope
              }"
            >
            </ng-container>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-tab>
      <mat-tab
        label="Management"
        *ngIf="companyService.userManagementModules.length > 0"
      >
        <mat-grid-list
          [cols]="tabCols"
          rowHeight="130px"
          style="margin-top: 10px"
        >
          <mat-grid-tile
            *ngFor="let module of companyService.userManagementModules"
          >
            <ng-container
              [ngTemplateOutlet]="moduleCard"
              [ngTemplateOutletContext]="{
                icon: module.maticon,
                title: module.title,
                action: module.path,
                scope: module.scope
              }"
            >
            </ng-container>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-tab>
      <mat-tab label="HR" *ngIf="companyService.userHrModules.length > 0">
        <mat-grid-list
          [cols]="tabCols"
          rowHeight="130px"
          style="margin-top: 10px"
        >
          <mat-grid-tile *ngFor="let module of companyService.userHrModules">
            <ng-container
              [ngTemplateOutlet]="moduleCard"
              [ngTemplateOutletContext]="{
                icon: module.maticon,
                title: module.title,
                action: module.path,
                scope: module.scope
              }"
            >
            </ng-container>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-tab>
      <mat-tab
        label="{{ 'welcome.erp' | translate }}"
        *ngIf="companyService.userErpModules.length > 0"
      >
        <mat-grid-list
          [cols]="tabCols"
          rowHeight="130px"
          style="margin-top: 10px"
        >
          <mat-grid-tile
            *ngFor="let erpModule of companyService.userErpModules"
          >
            <ng-container
              [ngTemplateOutlet]="moduleCard"
              [ngTemplateOutletContext]="{
                icon: erpModule.maticon,
                title: translateService.instant(erpModule.title),
                action: erpModule.path,
                scope: 'erp'
              }"
            >
            </ng-container>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-tab>
      <mat-tab
        label="{{ 'Modules.maintenance' | translate }}"
        *ngIf="companyService.userMaintenanceModules.length > 0"
      >
        <mat-grid-list
          [cols]="tabCols"
          rowHeight="130px"
          style="margin-top: 10px"
        >
          <mat-grid-tile
            *ngFor="let module of companyService.userMaintenanceModules"
          >
            <ng-container
              [ngTemplateOutlet]="moduleCard"
              [ngTemplateOutletContext]="{
                icon: module.maticon,
                title: module.title,
                action: module.path,
                scope: module.scope
              }"
            >
            </ng-container>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-tab>

      <mat-tab
        label="{{ 'Modules.customentity' | translate }}"
        *ngIf="companyService.userCustomEntityModules.length > 0"
      >
        <mat-grid-list
          [cols]="tabCols"
          rowHeight="130px"
          style="margin-top: 10px"
        >
          <mat-grid-tile
            *ngFor="
              let entity of entityService.storedActiveCustomEntities$ | async
            "
          >
            <ng-container
              [ngTemplateOutlet]="moduleCard"
              [ngTemplateOutletContext]="{
                icon: 'group_work',
                title: language === 'en' ? entity.name_en : entity.name_gr,
                action: 'customentity/entity/' + entity.entity_code,
                scope: 'customentity'
              }"
            >
            </ng-container>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
