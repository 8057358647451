<div *ngIf="ENTITY" style="margin: 0 10px">
  <div>
    <div class="globalFilterBar">
      <section
        class="ownerFilter"
        [ngClass]="{
          baseFilters: !(isHandset$ | async),
          'baseFilters-M': (isHandset$ | async)
        }"
      >
        <span>Owner</span>
        <app-user-filter
          (selectUserAction)="selectOwnerFiler($event)"
          [SHOWUSER]="FILTERS.ownerId ? true : false"
        ></app-user-filter>
        <button
          mat-icon-button
          color="warn"
          *ngIf="FILTERS.ownerId"
          (click)="clearOwnerId()"
        >
          <mat-icon>clear</mat-icon>
        </button>
      </section>

      <section
        [ngClass]="{
          baseFilters: !(isHandset$ | async),
          'baseFilters-M': (isHandset$ | async)
        }"
      >
        <mat-form-field class="fullwidth">
          <mat-label># ID</mat-label>
          <input matInput type="number" [(ngModel)]="FILTERS.itemId" />
        </mat-form-field>
        <button
          mat-icon-button
          color="warn"
          *ngIf="FILTERS.itemId"
          (click)="clearItemId()"
        >
          <mat-icon>clear</mat-icon>
        </button>
      </section>

      <section
        [ngClass]="{
          baseFilters: !(isHandset$ | async),
          'baseFilters-M': (isHandset$ | async)
        }"
      >
        <mat-form-field class="fullwidth">
          <input
            matInput
            [matDatepicker]="picker"
            [(ngModel)]="FILTERS.createdAtSearchDate"
          />
          <mat-label>{{ 'Generic.createdDate' | translate }}</mat-label>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <button
          mat-icon-button
          color="warn"
          *ngIf="FILTERS.createdAtSearchDate"
          (click)="clearCreatedAtSearchDate()"
        >
          <mat-icon>clear</mat-icon>
        </button>
      </section>
      <section
        [ngClass]="{
          baseFilters: !(isHandset$ | async),
          'baseFilters-M': (isHandset$ | async)
        }"
      >
        <mat-form-field class="fullwidth">
          <mat-select
            *ngIf="FILTERS"
            [(ngModel)]="FILTERS.trashed"
            (ngModelChange)="changeFilter()"
          >
            <mat-option [value]="2">
              {{ 'Generic.all' | translate }}
            </mat-option>
            <mat-option [value]="0">
              {{ 'Generic.active' | translate }}
            </mat-option>
            <mat-option [value]="1">
              {{ 'Generic.deactivate' | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </section>

      <section
        [ngClass]="{
          baseFilters: !(isHandset$ | async),
          'baseFilters-M': (isHandset$ | async)
        }"
        *ngIf="isHandset$ | async"
      >
        <mat-form-field class="fullwidth">
          <mat-label>{{ 'Generic.savedFilters' | translate }}</mat-label>
          <mat-select (selectionChange)="applySavedFilter($event.value)">
            <mat-option [value]="null"> - </mat-option>
            <mat-option
              *ngFor="let savedFilter of savedFilters; let i = index"
              [value]="savedFilter"
            >
              {{ savedFilter.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </section>

      <section *ngFor="let attr of fields">
        <div *ngIf="showSearchField(attr.id)">
          <!--          <mat-form-field class="fullwidth">-->
          <!--            <mat-label>{{getDBFldTransateLabel(attr)}}</mat-label>-->
          <!--            <input matInput [(ngModel)]="attr.search_from">-->
          <!--          </mat-form-field>-->

          <section
            *ngIf="attr.field_specs.fld_code == 'singleEntity'"
            style="display: flex"
          >
            <mat-form-field style="width: 140px">
              <mat-label>{{ attr | translateFieldLabel }}</mat-label>
              <input matInput type="number" [(ngModel)]="attr.search_from" />
            </mat-form-field>
            <app-single-entity-filter [ITEM]="attr"> </app-single-entity-filter>
          </section>

          <div
            *ngIf="attr.field_specs.fld_code == 'scale'"
            style="max-width: 400px; margin-top: 6px"
          >
            <mat-label>{{ attr | translateFieldLabel }}</mat-label>
            <mat-slider
              style="width: 100px"
              max="5"
              min="1"
              step="1"
              discrete
              color="primary"
              showTickMarks
            >
              <input matSliderThumb [(ngModel)]="attr.search_from" />
            </mat-slider>
            <mat-slider
              style="width: 100px"
              max="5"
              min="1"
              step="1"
              discrete
              color="primary"
              showTickMarks
            >
              <input matSliderThumb [(ngModel)]="attr.search_to" />
            </mat-slider>
            <button
              mat-icon-button
              color="warn"
              *ngIf="attr.search_to || attr.search_from"
              (click)="clearSearchValues(attr)"
            >
              <mat-icon>clear</mat-icon>
            </button>
          </div>

          <section
            *ngIf="attr.field_specs.fld_code == 'user'"
            class="ownerFilter"
          >
            <span style="margin-top: 10px">{{
              attr | translateFieldLabel
            }}</span>
            <app-user-filter
              [search_from]="attr.search_from"
              [ITEM]="attr"
              [SHOWUSER]="attr.search_from ? true : false"
            ></app-user-filter>
            <button
              mat-icon-button
              color="warn"
              *ngIf="attr.search_to || attr.search_from"
              (click)="clearSearchValues(attr)"
            >
              <mat-icon>clear</mat-icon>
            </button>
          </section>

          <div
            *ngIf="attr.field_specs.fld_code == 'number'"
            style="width: 300px"
          >
            <mat-form-field style="width: 150px">
              <mat-label>{{ attr | translateFieldLabel }} - Από</mat-label>
              <input matInput type="number" [(ngModel)]="attr.search_from" />
            </mat-form-field>
            <mat-form-field style="width: 150px">
              <mat-label>{{ attr | translateFieldLabel }} - Έως</mat-label>
              <input
                matInput
                type="number"
                [(ngModel)]="attr.search_to"
                (ngModelChange)="autoFillFrom(attr)"
              />
            </mat-form-field>
          </div>

          <mat-form-field
            *ngIf="
              attr.field_specs.fld_code == 'text' ||
              attr.field_specs.fld_code == 'email' ||
              attr.field_specs.fld_code == 'tel'
            "
            style="margin-right: 10px"
          >
            <mat-label>{{ attr | translateFieldLabel }}</mat-label>
            <input matInput [(ngModel)]="attr.search_from" />
          </mat-form-field>

          <app-month-filter
            #monthFilter
            *ngIf="attr.field_specs.fld_code == 'month'"
            [ITEM]="attr"
          ></app-month-filter>

          <!--          <mat-form-field *ngIf="attr.field_specs.fld_code == 'dropdown'">-->
          <!--            <mat-label>{{ attr | translateFieldLabel }}</mat-label>-->
          <!--            <mat-select [(ngModel)]="attr.search_from" multiple>-->
          <!--              <mat-option-->
          <!--                *ngFor="let option of attr.for_entity.split('|')"-->
          <!--                [value]="option"-->
          <!--              >-->
          <!--                {{ option }}-->
          <!--              </mat-option>-->
          <!--            </mat-select>-->
          <!--          </mat-form-field>-->

          @if (attr.field_specs.fld_code == 'dropdown') {
            <app-dropdown-filter [ITEM]="attr"></app-dropdown-filter>
          }

          <section
            *ngIf="attr.field_specs.fld_code == 'datetime'"
            class="full-width"
          >
            <mat-label>{{ attr | translateFieldLabel }}</mat-label>
            <app-datetime-filter
              [FROMORTO]="'from'"
              [ITEM]="attr"
            ></app-datetime-filter>
            <app-datetime-filter
              [FROMORTO]="'το'"
              [ITEM]="attr"
              (changeDate)="autoFillFrom(attr)"
            >
            </app-datetime-filter>
            <button
              mat-icon-button
              color="warn"
              *ngIf="attr.search_to || attr.search_from"
              (click)="clearSearchValues(attr)"
            >
              <mat-icon>clear</mat-icon>
            </button>
          </section>

          <section
            *ngIf="attr.field_specs.fld_code == 'checkbox'"
            class="full-width"
            style="margin-top: 15px; margin-right: 15px"
          >
            <span style="margin-top: 10px; margin-right: 2px">{{
              attr | translateFieldLabel
            }}</span>
            <app-checkbox-filter [ITEM]="attr"> </app-checkbox-filter>
            <button
              mat-icon-button
              color="warn"
              *ngIf="attr.search_to || attr.search_from"
              (click)="clearSearchValues(attr)"
            >
              <mat-icon>clear</mat-icon>
            </button>
          </section>

          <section
            *ngIf="attr.field_specs.fld_code == 'productlist'"
            style="display: flex"
          >
            <mat-form-field style="width: 140px">
              <input matInput type="number" [(ngModel)]="attr.search_from" />
            </mat-form-field>
            <app-single-entity-filter [ITEM]="attr"> </app-single-entity-filter>
          </section>

          <button
            mat-icon-button
            color="warn"
            *ngIf="
              (attr.search_to || attr.search_from) &&
              attr.field_specs.fld_code !== 'user' &&
              attr.field_specs.fld_code !== 'checkbox' &&
              attr.field_specs.fld_code !== 'scale' &&
              attr.field_specs.fld_code !== 'datetime'
            "
            (click)="clearSearchValues(attr)"
          >
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </section>

      <button
        *ngIf="!(isHandset$ | async)"
        mat-button
        color="primary"
        style="height: 56px"
        (click)="toogleFilters = !toogleFilters"
      >
        <mat-icon *ngIf="toogleFilters">keyboard_arrow_right</mat-icon>
        <mat-icon *ngIf="!toogleFilters">keyboard_arrow_down</mat-icon>
        <mat-icon>filter_alt</mat-icon>
      </button>
    </div>
    <div [hidden]="toogleFilters">
      <div style="width: 100%; display: flex; margin-bottom: 50px">
        <mat-card class="filterCard">
          <table>
            <tr>
              <td width="10%">{{ 'Generic.linkEnity' | translate }}</td>
              <td width="30%">
                <mat-form-field style="width: 180px; margin-top: 4px">
                  <mat-label>#</mat-label>
                  <input
                    matInput
                    [(ngModel)]="FILTERS.connectedWith"
                    type="number"
                  />
                </mat-form-field>
              </td>
              <td width="50%">
                <mat-form-field>
                  <mat-label>{{ 'Company.entities' | translate }}</mat-label>
                  <mat-select [(ngModel)]="selectedConnectEntity">
                    <mat-option
                      *ngFor="let entity of entities"
                      [value]="entity"
                    >
                      {{ getEntityNameTranslate(entity) }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <button
                  mat-icon-button
                  style="display: inline"
                  (click)="selectEntityIDiag()"
                >
                  <mat-icon color="primary">search</mat-icon>
                </button>
              </td>
              <td width="10%">
                <button
                  mat-icon-button
                  color="primary"
                  *ngIf="FILTERS.connectedWith"
                  (click)="clearConnectedWith()"
                >
                  <mat-icon>clear</mat-icon>
                </button>
              </td>
            </tr>

            <tr *ngFor="let attr of fields" style="height: 60px">
              <!--              <td style="width: 10px">-->
              <!--                <mat-slide-toggle color="primary" matTooltip="pin on top" [checked]="showSearchField(attr.fld_code)" (change)="addToPreferences(attr)"></mat-slide-toggle>-->
              <!--              </td>-->
              <td width="10%">
                <button
                  mat-icon-button
                  [ngStyle]="{
                    'background-color': showSearchField(attr.id)
                      ? '#babfc4'
                      : 'transparent'
                  }"
                  matTooltip="pin on top"
                  (click)="addToPreferences(attr)"
                >
                  <mat-icon>push_pin</mat-icon>
                </button>
              </td>
              <td width="30%">
                <mat-label>{{ attr | translateFieldLabel }} </mat-label>
              </td>
              <td *ngIf="!showSearchField(attr.id)">
                <section
                  *ngIf="attr.field_specs.fld_code == 'singleEntity'"
                  style="display: flex"
                >
                  <mat-form-field style="width: 140px">
                    <input
                      matInput
                      type="number"
                      [(ngModel)]="attr.search_from"
                    />
                  </mat-form-field>
                  <app-single-entity-filter [ITEM]="attr">
                  </app-single-entity-filter>
                </section>

                <section *ngIf="attr.field_specs.fld_code == 'scale'">
                  <mat-slider
                    style="width: 189px"
                    max="5"
                    min="1"
                    step="1"
                    discrete
                    color="primary"
                    showTickMarks
                  >
                    <input matSliderThumb [(ngModel)]="attr.search_from" />
                  </mat-slider>
                </section>

                <section *ngIf="attr.field_specs.fld_code == 'user'">
                  <app-user-filter
                    [search_from]="attr.search_from"
                    [ITEM]="attr"
                    [SHOWUSER]="attr.search_from ? true : false"
                  ></app-user-filter>
                </section>

                <mat-form-field
                  *ngIf="attr.field_specs.fld_code == 'number'"
                  class="full-width"
                >
                  <mat-label>Από</mat-label>
                  <input
                    matInput
                    type="number"
                    [(ngModel)]="attr.search_from"
                  />
                </mat-form-field>

                <mat-form-field
                  *ngIf="
                    attr.field_specs.fld_code == 'text' ||
                    attr.field_specs.fld_code == 'email' ||
                    attr.field_specs.fld_code == 'tel'
                  "
                  style="margin-right: 10px"
                >
                  <input matInput [(ngModel)]="attr.search_from" />
                </mat-form-field>

                <app-month-filter
                  #monthFilter
                  *ngIf="attr.field_specs.fld_code == 'month'"
                  [ITEM]="attr"
                ></app-month-filter>

                @if (attr.field_specs.fld_code == 'dropdown') {
                  <app-dropdown-filter [ITEM]="attr"></app-dropdown-filter>
                }

                <section
                  *ngIf="attr.field_specs.fld_code == 'datetime'"
                  class="full-width"
                >
                  <app-datetime-filter
                    [FROMORTO]="'from'"
                    [ITEM]="attr"
                  ></app-datetime-filter>
                </section>

                <section
                  *ngIf="attr.field_specs.fld_code == 'checkbox'"
                  class="full-width"
                >
                  <app-checkbox-filter [ITEM]="attr"> </app-checkbox-filter>
                </section>

                <section
                  *ngIf="attr.field_specs.fld_code == 'productlist'"
                  style="display: flex"
                >
                  <mat-form-field style="width: 140px">
                    <input
                      matInput
                      type="number"
                      [(ngModel)]="attr.search_from"
                    />
                  </mat-form-field>
                  <app-single-entity-filter [ITEM]="attr">
                  </app-single-entity-filter>
                </section>
              </td>
              <td *ngIf="!showSearchField(attr.id)">
                <section *ngIf="attr.field_specs.fld_code == 'scale'">
                  <mat-slider
                    style="width: 189px"
                    max="5"
                    min="1"
                    step="1"
                    discrete
                    color="primary"
                    showTickMarks
                  >
                    <input
                      matSliderThumb
                      [(ngModel)]="attr.search_to"
                      (ngModelChange)="autoFillFrom(attr)"
                    />
                  </mat-slider>
                </section>

                <mat-form-field
                  *ngIf="attr.field_specs.fld_code == 'number'"
                  class="full-width"
                >
                  <mat-label>Έως</mat-label>
                  <input
                    matInput
                    type="number"
                    [(ngModel)]="attr.search_to"
                    (ngModelChange)="autoFillFrom(attr)"
                  />
                </mat-form-field>

                <section *ngIf="attr.field_specs.fld_code == 'datetime'">
                  <app-datetime-filter
                    [FROMORTO]="'το'"
                    [ITEM]="attr"
                    (changeDate)="autoFillFrom(attr)"
                  >
                  </app-datetime-filter>
                </section>
              </td>
              <td *ngIf="!showSearchField(attr.id)" width="">
                <div style="width: 60px">
                  <button
                    mat-icon-button
                    color="primary"
                    *ngIf="attr.search_to || attr.search_from"
                    (click)="clearSearchValues(attr)"
                  >
                    <mat-icon color="warn">clear</mat-icon>
                  </button>
                </div>
              </td>
            </tr>
          </table>
        </mat-card>

        <mat-card class="savedFiltersCard">
          <mat-form-field>
            <mat-label>{{ 'Generic.filterName' | translate }} </mat-label>
            <input matInput type="text" [(ngModel)]="newFilterName" />
          </mat-form-field>
          <button mat-icon-button (click)="saveFilters()">
            <mat-icon color="primary">save</mat-icon>
          </button>

          <div mat-subheader>{{ 'Generic.filterList' | translate }}</div>
          <mat-list class="saved-filters-list">
            <mat-list-item
              class="savedFilter"
              *ngFor="let savedFilter of savedFilters; let i = index"
              (click)="applySavedFilter(savedFilter)"
            >
              <div matListItemTitle>{{ i + 1 }}) {{ savedFilter.title }}</div>
              <button
                mat-icon-button
                matListItemMeta
                (click)="deleteFilter(savedFilter)"
              >
                <mat-icon color="warn">clear</mat-icon>
              </button>
            </mat-list-item>
          </mat-list>
        </mat-card>
      </div>
    </div>
  </div>
</div>
