import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class SalesDataService extends BaseService {
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public getSalesData(
    customerId: number,
    productId: number
  ): Observable<{
    success: boolean;
    data: { currentYearSales: number; lastYearSales: number; customerStock: number } | null;
  }> {
    return this.get<{
      success: boolean;
      data: { currentYearSales: number; lastYearSales: number; customerStock: number } | null;
    }>(
      `${this.apiUrl}entity/misc/sales?customerId=${customerId}&productId=${productId}`
    );
  }
}
