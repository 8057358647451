import { inject, Pipe, PipeTransform } from '@angular/core';
import { Workflow } from '../model';
import { TranslateService } from '@ngx-translate/core';
import { WorkflowStatusEnum } from '../enums/WorkflowStatus.enum';

@Pipe({
  name: 'workflowCompletionStatus',
})
export class WorkflowCompletionStatusPipe implements PipeTransform {
  private readonly translateService = inject(TranslateService);
  transform(workflow: Workflow): string {
    const completedSteps = workflow.workflowSteps.filter(
      (step) => step.status === WorkflowStatusEnum.COMPLETE
    ).length;
    const totalSteps = workflow.workflowTemplate.workflowTemplateSteps.length;
    const completionPercentage = ((completedSteps / totalSteps) * 100).toFixed(
      2
    );
    return `${completedSteps} ${this.translateService.instant('Generic.from')} ${totalSteps} (${completionPercentage}%)`;
  }
}
