import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { NotesTableLine } from '../../../../../model';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NotesService } from '../../../../../services/notes.service';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '../../../../../services/settings.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.css'],
})
export class NotesListComponent implements AfterViewInit {
  selectedColumns: string[] = [
    'field_1',
    'field_2',
    'field_3',
    'field_4',
    'field_5',
    'delete',
  ];
  dataSource: any;
  @ViewChild(MatTable) table: MatTable<any>;
  @Input() ITEM: any;
  @Input() ITEMID: any;
  @Input() allAttr: any;
  @Input() disabled: boolean;

  constructor(
    private readonly notesService: NotesService,
    private readonly translateService: TranslateService,
    private readonly settingsService: SettingsService,
    private readonly cdRef: ChangeDetectorRef
  ) {
    this.dataSource = new MatTableDataSource();
  }

  allColumns = [
    {
      name: 'field_1',
      desc:
        this.settingsService.getSavedSettings('noteListFld1')[0]?.value != null
          ? this.settingsService.getSavedSettings('noteListFld1')[0]?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 1',
    },
    {
      name: 'field_2',
      desc:
        this.settingsService.getSavedSettings('noteListFld2')[0]?.value != null
          ? this.settingsService.getSavedSettings('noteListFld2')[0]?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 2',
    },
    {
      name: 'field_3',
      desc:
        this.settingsService.getSavedSettings('noteListFld3')[0]?.value != null
          ? this.settingsService.getSavedSettings('noteListFld3')[0]?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 3',
    },
    {
      name: 'field_4',
      desc:
        this.settingsService.getSavedSettings('noteListFld4')[0]?.value != null
          ? this.settingsService.getSavedSettings('noteListFld4')[0]?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 4',
    },
    {
      name: 'field_5',
      desc:
        this.settingsService.getSavedSettings('noteListFld5')[0]?.value != null
          ? this.settingsService.getSavedSettings('noteListFld5')[0]?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 5',
    },
    {
      name: 'field_6',
      desc:
        this.settingsService.getSavedSettings('noteListFld6')[0]?.value != null
          ? this.settingsService.getSavedSettings('noteListFld6')[0]?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 6',
    },
    {
      name: 'field_7',
      desc:
        this.settingsService.getSavedSettings('noteListFld7')[0]?.value != null
          ? this.settingsService.getSavedSettings('noteListFld7')[0]?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 7',
    },
    {
      name: 'field_8',
      desc:
        this.settingsService.getSavedSettings('noteListFld8')[0]?.value != null
          ? this.settingsService.getSavedSettings('noteListFld8')[0]?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 8',
    },
    {
      name: 'field_9',
      desc:
        this.settingsService.getSavedSettings('noteListFld9')[0]?.value != null
          ? this.settingsService.getSavedSettings('noteListFld9')[0]?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 9',
    },
    {
      name: 'field_10',
      desc:
        this.settingsService.getSavedSettings('noteListFld10')[0]?.value != null
          ? this.settingsService.getSavedSettings('noteListFld10')[0]?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 10',
    },
    {
      name: 'delete',
      desc: this.translateService.instant('QuoteConfigurator.del'),
    },
  ];

  ngAfterViewInit(): void {
    this.getSavedColumns();
    if (this.ITEMID != 'new') {
      this.getSavedNoteLines(this.ITEMID, this.ITEM.id);
    } else {
      this.ITEM.noteLines = [];
      this.dataSource = this.ITEM.noteLines;
      this.addNoteLine(null);
    }
  }

  public addNoteLine(_item: any) {
    let newLine: NotesTableLine = new NotesTableLine();
    newLine.column_1 = null;
    newLine.column_2 = null;
    newLine.column_3 = null;
    newLine.column_4 = null;
    newLine.column_5 = null;
    newLine.column_6 = null;
    newLine.column_7 = null;
    newLine.column_8 = null;
    newLine.column_9 = null;
    newLine.column_10 = null;

    this.ITEM.noteLines.push(newLine);
    this.table.renderRows();
  }

  private getSavedNoteLines(entdataId: number, entfieldId: number) {
    this.notesService
      .getNotesTableLines(entdataId, entfieldId)
      .pipe(take(1))
      .subscribe((res) => {
        this.ITEM.noteLines = res;
        this.dataSource = this.ITEM.noteLines;
        this.addNoteLine(null);
      });
  }

  removeLine(index: number) {
    this.ITEM.noteLines.splice(index, 1);
    this.table.renderRows();
  }

  storeSettings() {
    localStorage.setItem(
      `${this.ITEM.fld_code_ent}_columns`,
      JSON.stringify(this.selectedColumns)
    );
    this.table.renderRows();
  }

  private getSavedColumns() {
    let savedColumns = JSON.parse(
      localStorage.getItem(`${this.ITEM.fld_code_ent}_columns`)
    );
    if (savedColumns !== null) {
      this.selectedColumns = savedColumns;
      this.cdRef.detectChanges();
    }
  }
}
