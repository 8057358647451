import {
  Company,
  ReturnData,
  Department,
  Module,
  CompanyReport,
} from '../model';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ReportFilter } from '../common/filters';
import { BehaviorSubject, Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends BaseService {
  private helper = new JwtHelperService();
  teamworkModules = new BehaviorSubject<Module[]>([]);
  getSavedTabState = new BehaviorSubject<boolean>(false);
  userModules: Module[] = [];
  userAdminModules: Module[] = [];
  userSupplyChainModules: Module[] = [];
  userSalesModules: Module[] = [];
  userManagementModules: Module[] = [];
  userHrModules: Module[] = [];
  userErpModules: Module[] = [];
  userCustomEntityModules: Module[] = [];
  userMaintenanceModules: Module[] = [];
  favoriteModules: Module[] = [];
  favModuleIdsToString = '';

  constructor(
    private httpClient: HttpClient,
    private translateService: TranslateService
  ) {
    super(httpClient);
  }

  public getCompanies(): Observable<Company[]> {
    return this.get<Company[]>(this.apiUrl + 'companies');
  }

  public newCompany(company: Company): Observable<ReturnData<Company>> {
    return this.post<ReturnData<Company>>(this.apiUrl + 'companies', company);
  }

  public getCompanyById(companyId: string): Observable<Company> {
    return this.get<Company>(this.apiUrl + 'companies/' + companyId);
  }

  public updateCompany(company: Company): Observable<ReturnData<any>> {
    return this.put<ReturnData<any>>(this.apiUrl + 'companies', company);
  }

  public deleteCompany(company: Company): Observable<ReturnData<any>> {
    return this.delete<ReturnData<any>>(
      this.apiUrl + 'companies/' + company.id
    );
  }

  public addDepartment(
    department: Department
  ): Observable<ReturnData<Company>> {
    return this.post<ReturnData<Company>>(
      this.apiUrl + 'departments',
      department
    );
  }

  public editDepartment(
    department: Department
  ): Observable<ReturnData<Company>> {
    return this.put<ReturnData<Company>>(
      this.apiUrl + 'departments',
      department
    );
  }
  public deleteDepartment(
    department: Department
  ): Observable<ReturnData<Company>> {
    return this.delete<ReturnData<Company>>(
      this.apiUrl + 'departments/' + department.id
    );
  }

  public getModules(): Observable<Module[]> {
    return this.get<Module[]>(this.apiUrl + 'modules');
  }

  public getCompanyReport(
    analysisFilter: ReportFilter
  ): Observable<CompanyReport> {
    return this.post<CompanyReport>(
      this.apiUrl + 'admin/report/' + analysisFilter.companyId,
      analysisFilter
    );
  }

  public getCompanyForUser(): Observable<Company> {
    return this.get<Company>(this.apiUrl + 'companyForUser');
  }

  getUserAuthorizedModules() {
    const jwtToken = localStorage.getItem('token');
    const scopesArray = this.helper.decodeToken(jwtToken).scopes;
    const teamworkModules = this.teamworkModules.getValue();
    const userModules = teamworkModules.filter((mod) =>
      scopesArray.includes(mod.scope)
    );
    this.userModules = userModules.map((mod) => ({
      ...mod,
      title: this.translateService.instant(`Modules.${mod.path}`),
    }));
    this.storeUserModules();
  }

  private storeUserModules() {
    this.userAdminModules = this.userModules.filter(
      (mod) => mod.section === 'admin'
    );
    this.userSupplyChainModules = this.userModules.filter(
      (mod) => mod.section === 'supplyChain'
    );
    this.userSalesModules = this.userModules.filter(
      (mod) => mod.section === 'sales'
    );
    this.userManagementModules = this.userModules.filter(
      (mod) => mod.section === 'management'
    );
    this.userHrModules = this.userModules.filter((mod) => mod.section === 'HR');
    this.userErpModules = this.userModules.filter(
      (mod) => mod.section === 'warehouse'
    );
    this.userMaintenanceModules = this.userModules.filter(
      (mod) => mod.section === 'maintenance'
    );
    this.userCustomEntityModules = this.userModules.filter(
      (mod) => mod.section === 'customEntity'
    );
  }

  getUserFavoriteModules() {
    return this.get<any>(this.apiUrl + 'modules/favorites')
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.favModuleIdsToString = res.modules;
          const favModulesIds = this.favModuleIdsToString
            .split(',')
            .map((id) => Number(id));
          this.favoriteModules = this.userModules.filter((x) =>
            favModulesIds.includes(x.id)
          );
        }
        this.getSavedTabState.next(true);
      });
  }

  saveUserFavoriteModules() {
    const favModuleIds = this.favoriteModules.map((x) => x.id).join(',');
    if (favModuleIds !== this.favModuleIdsToString) {
      return this.post<any>(this.apiUrl + 'modules/favorites', {
        modules: favModuleIds,
      })
        .pipe(take(1))
        .subscribe((res) => {
          if (res.success) {
            this.favModuleIdsToString = favModuleIds;
          }
        });
    }
  }
}
