import { Component, Input } from '@angular/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { TranslateFieldLabelPipe } from '../../../../../pipes/translate-field-label.pipe';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-dropdown-filter',
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    TranslateFieldLabelPipe,
    FormsModule,
  ],
  templateUrl: './dropdown-filter.component.html',
  styleUrl: './dropdown-filter.component.css',
})
export class DropdownFilterComponent {
  @Input() ITEM!: any;
}
