import {
  Component,
  Input,
  SimpleChanges,
  ViewChild,
  Output,
  EventEmitter,
  AfterViewInit,
  DestroyRef,
  OnInit,
  OnChanges,
} from '@angular/core';

import { PagedData, SavedFilter, Entity, SavedColumns } from 'src/app/model';
import { MatPaginator } from '@angular/material/paginator';
import { BaseFilter, EntityItemFilter } from '../filters';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { EntityService } from '../../services/entity.service';
import { NotificationService } from 'src/app/services/notification.service';
import { LoginService } from 'src/app/services/login.service';
import { LayoutService } from '../../services/layout.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FileUploadService } from '../../services/file-upload.service';
import { ParentProductService } from 'src/app/services/parentproduct.service';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.css'],
})
export class GridComponent implements AfterViewInit, OnInit, OnChanges {
  @Input() ID: string;
  @Input() ELEMENT_DATA: PagedData<any>;
  @Input() ARRAY_DATA: any[];
  @Input() ATTR: any[];
  @Input() PAGESIZEOPTIONS: number[] = [];
  @Output() getData = new EventEmitter<BaseFilter>();
  @Output() ONROWCLICK = new EventEmitter<any>();
  @Output() ONACTIONCLICK = new EventEmitter<any>();
  @Output() ONBALKACTIONCLICK = new EventEmitter<any>();
  @Output() ONCOLSEARCH = new EventEmitter<any>();
  @Output() ONRESETFILTERS = new EventEmitter<any>();
  @Output() fillNonFieldFilterValues = new EventEmitter<any>();
  @Input() FILTERS: EntityItemFilter;
  @Input() showSearch: boolean = true;
  @Input() SHOWTRASHFILTER: boolean = false;
  @Input() MULTISELECT: boolean = false;
  @Input() SEARCHONCOLS: boolean = false;
  @Input() ISDATAPAGED: boolean = true;
  @Input() ENTITY: Entity;
  @Input() ENT_CODE: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = [];
  selectedColumns: string[] = [];
  localstorageKey: string;
  dataSource: any;
  selection = new SelectionModel<any>(true, []);
  rowSearchChanged = new Subject<any>();

  showSaveViewDialog: boolean = false;
  viewName: string = '';
  savedColumns: SavedColumns[] = [];
  selectedView: SavedColumns;
  data: any[] = [];
  entity: string;
  savedFilters: SavedFilter[] = [];
  chosenSavedFilterId: number;
  customCheckboxState: boolean = false;
  filteredData: any[] = [];

  constructor(
    private entityService: EntityService,
    private notificationService: NotificationService,
    private loginService: LoginService,
    public layoutService: LayoutService,
    private destroyRef: DestroyRef,
    private fileUploadService: FileUploadService,
    private parentProductService: ParentProductService
  ) {
    this.rowSearchChanged.pipe(debounceTime(1000)).subscribe((res) => {
      this.searchOnColumn(res.ev, res.col);
    });
  }

  ngOnInit(): void {
    let isHandset: boolean = false;
    this.layoutService.isHandset$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((x) => {
        isHandset = x;
      });

    if (this.MULTISELECT) {
      this.selectedColumns.push('checkbox');
      this.selection.changed.subscribe((selectionModel) => {
        let selectedIds = selectionModel.source.selected.map(
          (entity) => entity.id
        );
        this.entityService.selectedItemIds.next(selectedIds);
      });
    }

    let additionalLocalStorageKey = isHandset ? 'mobile' : 'desktop';
    this.localstorageKey = 'grid_' + this.ID + '_' + additionalLocalStorageKey;
    let savedColumnsJSON = localStorage.getItem(this.localstorageKey);

    for (let column of this.ATTR) {
      this.displayedColumns.push(column.attr);
      if (!savedColumnsJSON && !isHandset && column.view) {
        this.selectedColumns.push(column.attr);
      }
      if (!savedColumnsJSON && isHandset && column.mobileView) {
        this.selectedColumns.push(column.attr);
      }
    }

    if (savedColumnsJSON) {
      let savedColumns = JSON.parse(savedColumnsJSON).filter(
        (x) =>
          x != 'deactivateBulkAction' &&
          x != 'activateBulkAction' &&
          x != 'editBulkAction'
      );
      this.selectedColumns = [];

      if (
        this.MULTISELECT &&
        savedColumns.indexOf((x) => x == 'checkbox') == -1
      ) {
        this.selectedColumns.push('checkbox');
      }
      for (let savedColumn of savedColumns) {
        if (this.displayedColumns.find((x) => x == savedColumn)) {
          this.selectedColumns.push(savedColumn);
        }
      }
      this.customCheckboxState === false;
    }

    this.loadSavedColumns();
    this.getSavedFiltersList();
    this.checkForActiveParentFilter();
  }

  public convertToDate(datestr: string): Date {
    return datestr ? new Date(datestr) : null;
  }
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges): void {
    let data = this.ISDATAPAGED ? this.ELEMENT_DATA?.data : this.ARRAY_DATA;

    this.dataSource = new MatTableDataSource(data);
    this.dataSource.sort = this.sort;
    this.selection.clear();
    if (this.FILTERS.currentPage == 1) {
      this.paginator?.firstPage();
    }
  }

  changeFilter() {
    this.getData.emit(this.FILTERS);
  }

  searchStr() {
    this.FILTERS.currentPage = 1;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.changeFilter();
  }

  changePage(event) {
    localStorage.setItem('gridPageSize', JSON.stringify(event.pageSize));
    this.FILTERS.pageSize = event.pageSize;
    this.FILTERS.currentPage = event.pageIndex + 1;
    this.changeFilter();
  }

  sortData(event) {}

  rowClicked(obj: any) {
    this.ONROWCLICK.emit(obj);
  }

  actionClicked(item: any, action: string, fldId) {
    let obj = {
      item: item,
      action: action,
      fldId: fldId,
    };
    this.ONACTIONCLICK.emit(obj);
  }

  bulkActionClicked(action: string, fldId) {
    let obj = {
      items: this.selection.selected,
      action: action,
      fldId: fldId,
    };
    this.ONBALKACTIONCLICK.emit(obj);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.selectedColumns,
      event.previousIndex + (this.MULTISELECT ? 1 : 0),
      event.currentIndex + (this.MULTISELECT ? 1 : 0)
    );
    localStorage.setItem(
      this.localstorageKey,
      JSON.stringify(this.selectedColumns)
    );
  }

  updateSelectedColumns($event) {
    this.selectedColumns = this.selectedColumns.filter(
      (item) => item !== 'checkbox'
    );
    let difference = this.selectedColumns
      .filter((item) => !$event.includes(item))
      .concat($event.filter((item) => !this.selectedColumns.includes(item)));
    if (this.selectedColumns.includes(difference[0])) {
      this.selectedColumns = this.selectedColumns.filter(
        (item) => item !== difference[0]
      );
    } else {
      this.selectedColumns.push(difference[0]);
    }
    this.storeSettings();
  }

  storeSettings(addCheckbox: boolean = true) {
    if (this.MULTISELECT && addCheckbox) {
      this.selectedColumns.unshift('checkbox');
    }
    localStorage.setItem(
      this.localstorageKey,
      JSON.stringify(this.selectedColumns)
    );
  }

  public getActionColumns() {
    return this.ATTR.filter(
      (x) => x.type == 'action' || x.type == 'bulk_action'
    );
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  getColumns(): any[] {
    return this.ATTR.filter((x) => x.type != 'bulk_action');
  }

  searchOnColumn(event, column: any) {
    const valueFrom = event.target.value;
    this.ONCOLSEARCH.emit({
      valueFrom: valueFrom,
      column: column,
      getData: true,
    });
  }

  getSearchColumns() {
    let searchColumns: string[] = [];
    for (let i = 0; i < this.selectedColumns.length; i++) {
      searchColumns.push('search_' + this.selectedColumns[i]);
    }

    return searchColumns;
  }

  addSavedColumns() {
    let newsavedColumns: SavedColumns = new SavedColumns();
    newsavedColumns.title = this.viewName;
    newsavedColumns.selected_columns = this.selectedColumns.join(',');
    newsavedColumns.entity_code = this.ENT_CODE || this.ID;
    newsavedColumns.user_id = this.loginService.getLoginUser().id;

    this.entityService
      .saveColumns(newsavedColumns)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.success) {
          this.notificationService.showSnackbarMessage(
            'Messages.successfulSave'
          );
          this.savedColumns.push(newsavedColumns);
          this.viewName = '';
        } else {
          this.notificationService.showSnackbarMessage('Messages.failSave');
        }
      });
  }

  applySavedColumns(savedColumnsView: SavedColumns) {
    const selectedColumnsArray = savedColumnsView.selected_columns.split(',');

    this.displayedColumns = [...selectedColumnsArray];
    this.selectedColumns = [...selectedColumnsArray];

    this.selectedView = savedColumnsView;
    this.storeSettings(false);
  }

  deleteSavedColumns(view: SavedColumns) {
    this.entityService
      .deleteView(view.id)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.success) {
          const index = this.savedColumns.indexOf(view);
          if (index !== -1) {
            this.savedColumns.splice(index, 1);
          }
        }
      });
  }

  cancelSaveView() {
    this.showSaveViewDialog = false;
    this.viewName = '';
  }

  loadSavedColumns() {
    this.entityService
      .getSavedColumns(this.ENT_CODE)
      .pipe(take(1))
      .subscribe((res) => {
        this.savedColumns = res;
      });
  }

  toggleSaveViewDialog(event: MouseEvent) {
    event.stopPropagation();
    if (!this.showSaveViewDialog) {
      this.viewName = '';
    }
    this.showSaveViewDialog = !this.showSaveViewDialog;
  }

  onExportToExcel() {
    const gridColumns = this.selectedColumns.filter(
      (column) => column !== 'checkbox'
    );
    this.entityService
      .exportGridToExcel(this.FILTERS, this.ENT_CODE, gridColumns)
      .pipe(take(1))
      .subscribe((response: Blob | null) => {
        if (response !== null) {
          const blob = new Blob([response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          this.fileUploadService.downloadToDevice(blob, 'grid-data.xlsx');
        }
      });
  }

  public getSavedFiltersList() {
    if (this.SEARCHONCOLS) {
      this.entityService
        .getSavedFilters(this.ENT_CODE)
        .pipe(take(1))
        .subscribe((res) => {
          this.savedFilters = res;
          this.checkForPreferredFilter();
        });
    }
  }

  clearSavedFilter() {
    this.chosenSavedFilterId = null;
    const columns = this.getColumns();
    columns.forEach((col) => (col.searchValue = null));
    this.ONRESETFILTERS.emit({ fetchData: true });

    // If the field's code exists, remove the preferred filter from local storage
    if (this.entityService.entitySelectorFieldCode) {
      const localStorageKey =
        'popUpGridFilter_' + this.entityService.entitySelectorFieldCode;
      localStorage.removeItem(localStorageKey);
    }
  }

  applyFilter() {
    this.ONRESETFILTERS.emit({ fetchData: false });
    const chosenSavedFilter = this.savedFilters.filter(
      (filter) => filter.id === this.chosenSavedFilterId
    )[0];
    const columns = this.getColumns();
    columns.forEach((col) => (col.searchValue = null));

    if (!chosenSavedFilter) {
      return;
    }

    for (const filter of chosenSavedFilter.filters) {
      const column = columns.filter((col) => col.attr === filter.field_code)[0];
      const valueFrom = filter.search_from;
      const valueTo = filter.search_to ?? null;
      this.ONCOLSEARCH.emit({
        valueFrom: valueFrom,
        column: column,
        getData: false,
        valueTo: valueTo,
      });
      column.searchValue = valueFrom;
    }

    this.fillNonFieldFilterValues.emit(chosenSavedFilter);
    this.getData.emit();

    // If the field's code exists, save the preferred filter in local storage
    if (this.entityService.entitySelectorFieldCode) {
      const localStorageKey =
        'popUpGridFilter_' + this.entityService.entitySelectorFieldCode;
      localStorage.setItem(localStorageKey, String(chosenSavedFilter.id));
    }
  }

  private checkForPreferredFilter() {
    this.ONRESETFILTERS.emit({ fetchData: false });
    if (this.entityService.entitySelectorFieldCode) {
      const localStorageKey =
        'popUpGridFilter_' + this.entityService.entitySelectorFieldCode;
      const preferredFilterId = localStorage.getItem(localStorageKey);
      this.chosenSavedFilterId = preferredFilterId
        ? Number(preferredFilterId)
        : null;
      if (this.chosenSavedFilterId) {
        this.applyFilter();
      }
    }
  }

  private checkForActiveParentFilter() {
    // Get the state of the checkbox from the local storage
    const localStorageKey =
      'parentCheckbox_' + this.entityService.entitySelectorFieldCode;
    const parentCheckbox = localStorage.getItem(localStorageKey);

    // if the local storage entry is present, select the checkbox and enable the parent filter
    if (parentCheckbox) {
      this.customCheckboxState = true;
      this.parentProductService.setCheckboxState(this.customCheckboxState);
      this.applyParentProductFilter();
    }
  }

  onParentCheckboxChange() {
    this.parentProductService.setCheckboxState(this.customCheckboxState);

    // if the checkbox is selected apply the filter, else reset the filter
    if (this.customCheckboxState) {
      this.applyParentProductFilter();
    } else {
      this.ONRESETFILTERS.emit({ fetchData: true });
    }

    // Save the state of the checkbox to local storage
    const localStorageKey =
      'parentCheckbox_' + this.entityService.entitySelectorFieldCode;
    if (this.customCheckboxState) {
      localStorage.setItem(localStorageKey, String(this.customCheckboxState));
    } else {
      localStorage.removeItem(localStorageKey);
    }
  }

  private applyParentProductFilter(): void {
    const parentColumn = this.ATTR.find(
      (field) => field.attr === 'product_is_parent'
    );
    if (parentColumn) {
      this.ONCOLSEARCH.emit({
        valueFrom: 'true',
        column: parentColumn,
        getData: true,
        valueTo: null,
      });
    }
  }
}
