<mat-form-field>
  <mat-label>{{ ITEM | translateFieldLabel }}</mat-label>
  <mat-select [(ngModel)]="ITEM.search_from" multiple>
    @for (option of ITEM.for_entity.split('|'); track option) {
      <mat-option [value]="option">
        {{ option }}
      </mat-option>
    }
  </mat-select>
</mat-form-field>
