import { TranslateService } from '@ngx-translate/core';
import { Notify, NotifData, User, Question } from '../model';
import { NotificationService } from './notification.service';
import { LoginService } from './login.service';
import { Injectable } from '@angular/core';
import Echo from 'laravel-echo';
import { BehaviorSubject, Subject } from 'rxjs';
import { Platform } from '@angular/cdk/platform';
import { QuestionService } from './question.service';
import { environment } from '../../environments/environment';
import { take } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class WebsocketsService {
  audio = new Audio();
  echo: Echo<'pusher'>;
  notifications$ = new BehaviorSubject<Notify[]>([]);
  questions$ = new BehaviorSubject<Question[]>([]);
  public notifications: Notify[] = [];
  public questions: Question[] = [];
  public subject = new Subject<any>();
  public connectedUsers: User[] = [];
  constructor(
    private readonly loginService: LoginService,
    private readonly notificationService: NotificationService,
    private readonly translate: TranslateService,
    public platform: Platform,
    private readonly questionService: QuestionService
  ) {
    this.audio.src = './../../assets/sounds/newchat.mp3';
    this.audio.load();
    this.getNotifications();
    this.getQuestions();
  }

  getEcho() {
    return this.echo;
  }
  setup() {
    if (environment.production) {
      let token = this.loginService.getToken();
      this.echo = new Echo({
        broadcaster: 'pusher',
        key: 'asdfasdfSDFJSHADASDFSDFAS8D7F6ASD8F76897WY4EHIFKJLWRJHNGIJHWERIO8UTY239BDFGH45678TY3HU5GERIOTGUHWER9T78Y5390RTYHERYJRFHWETBYNTYKETYVHJERTYJKAERTYWE5RYTUKDFHNSDFGHASDFVJ6T58736',
        forceTLS: true,
        wsPort: 6001,
        wssPort: 443,
        cluster: 'mt1',
        disableStats: true,
        wsHost: window.location.hostname,

        auth: {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        },
      });
      this.listen();
    }
  }

  listen() {
    this.echo
      .private('App.User.' + this.loginService.getLoginUser().id)
      .notification((resNot) => {
        let newNotif = new Notify();
        newNotif.read_at = null;
        newNotif.data = new NotifData();
        newNotif.data.message = resNot.message;
        newNotif.data.entdata = resNot.entdata ?? null;
        newNotif.data.entity_code = resNot.entity_code ?? null;
        newNotif.data.entityChoice = resNot.entityChoice ?? null;
        newNotif.data.workflowStepId = resNot.workflowStepId ?? null;
        newNotif.data.non_entity_id = resNot.non_entity_id ?? null;
        newNotif.data.non_entity_type = resNot.non_entity_type ?? null;

        newNotif.id = resNot.id.toUpperCase();
        this.notificationService.notification$.next(newNotif);
        this.notificationService.sendLiveWebNotif(
          'TeamWork OS',
          newNotif.data.message
        );

        this.notifications = [...this.notifications, newNotif];
        this.notifications$.next(this.notifications);
      });

    this.echo
      .join('USERS')
      .here((users) => {
        this.connectedUsers = users;
      })
      .joining((user) => {
        this.connectedUsers.push(user);
      })
      .leaving((user) => {
        let indexToDel = this.connectedUsers.findIndex((x) => x.id == user.id);
        this.connectedUsers.splice(indexToDel, 1);
      });

    this.echo
      .private('Chat.User.' + this.loginService.getLoginUser().id)
      .listen('.NewChatMessage', (e) => {
        this.sendLiveWebNotif(e);
        this.audio.play();
        this.subject.next(e);
      });

    this.echo
      .private('Question.User.' + this.loginService.getLoginUser().id)
      .listen('.NewQuestion', (e) => {
        this.questions = [...this.questions, e.question];
        this.questions$.next(this.questions ?? []);
        this.audio.play();
      });
  }

  public getNotifications() {
    this.notificationService
      .getNotifications()
      .pipe(take(1))
      .subscribe((resNot) => {
        this.notifications = resNot;
        this.notifications$.next(this.notifications);
      });
  }

  public readNotification(readNotification: Notify) {
    let index = this.notifications.findIndex(
      (x) => x.id == readNotification.id
    );
    this.notifications = [
      ...this.notifications.slice(0, index),
      ...this.notifications.slice(index + 1),
    ];
    this.notifications$.next(this.notifications);
    this.notificationService
      .readNotification(readNotification)
      .pipe(take(1))
      .subscribe();
  }

  public readAllNotifications() {
    this.notificationService
      .readAllNotifications()
      .pipe(take(1))
      .subscribe((res) => {
        this.notifications = [];
        this.notifications$.next(this.notifications);
      });
  }

  private getQuestions() {
    this.questionService
      .getQuestions()
      .pipe(take(1))
      .subscribe((res) => {
        this.questions = res;
        this.questions$.next(this.questions ?? []);
      });
  }

  public answerQuestion(question: Question) {
    this.questionService
      .answerQuestion(question)
      .pipe(take(1))
      .subscribe((res) => {
        let indexForDel: number = this.questions.findIndex(
          (x) => x.id == question.id
        );
        this.questions = [
          ...this.questions.slice(0, indexForDel),
          ...this.questions.slice(indexForDel + 1),
        ];
        this.questions$.next(this.questions ?? []);
      });
  }

  private sendLiveWebNotif(e) {
    let title: string =
      this.translate.instant('Chat.newmsgFrom') + e.chatMsg.fullnamefrom;
    this.notificationService.sendLiveWebNotif(title, e.chatMsg.message);
  }
}
